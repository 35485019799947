import { Fab, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard, StylizedMoney, StylizedNumber } from 'components';
import { AssetType } from 'enums';
import { useCurrencies } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset, Trader } from 'types';
import { formatUtils } from 'utils';

type Props = {
  asset: Asset;
  trader?: Trader;
  onFundsRequest: () => void;
  onExchange: () => void;
};

export const AssetCard: FC<Props> = ({
  asset,
  trader,
  onFundsRequest,
  onExchange,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.my_assets',
  });
  const { defaultAssetCurrency } = useCurrencies();

  const isInsuranceDeposit = useMemo(
    () => asset.type === AssetType.InsuranceDeposit,
    [asset],
  );
  const title = useMemo(() => {
    if (asset.shop?.name) {
      return formatUtils.formatName(asset.shop);
    }

    return tCommon(`assets.type.${asset.type}`);
  }, [asset, tCommon]);

  const hasBalance = useMemo(() => !!asset.balance, [asset.balance]);

  const withdrawalDisabled = useMemo(() => {
    if (!hasBalance) {
      return true;
    }

    if (isInsuranceDeposit) {
      return !trader?.insuranceDepositWithdrawalAllowed;
    }

    return false;
  }, [hasBalance, isInsuranceDeposit, trader]);

  const renderAmount = useCallback(() => {
    if (isInsuranceDeposit && trader) {
      return (
        <div className="tw-flex">
          <StylizedNumber variant="h2" value={asset.balance} />
          <Typography variant="h2">{'/'}</Typography>
          <StylizedMoney
            variant="h2"
            value={trader.insuranceDepositAmount}
            code
            assetCurrencyId={asset.assetCurrencyId}
          />
        </div>
      );
    }

    return (
      <StylizedMoney
        variant="h2"
        value={asset.balance}
        code
        assetCurrencyId={asset.assetCurrencyId}
      />
    );
  }, [asset, isInsuranceDeposit, trader]);

  const renderFooter = useCallback(
    () => (
      <Stack direction="row" justifyContent="center" spacing={3}>
        {asset.assetCurrencyId === defaultAssetCurrency?.id ||
        asset.type === AssetType.InsuranceDeposit ? (
          <Fab
            variant="extended"
            size="medium"
            disabled={withdrawalDisabled}
            onClick={() => onFundsRequest()}
          >
            {t('buttons.withdrawal')}
          </Fab>
        ) : (
          <Fab
            variant="extended"
            size="medium"
            disabled={!hasBalance}
            onClick={() => onExchange()}
          >
            {t('buttons.exchange')}
          </Fab>
        )}
      </Stack>
    ),
    [
      asset.assetCurrencyId,
      asset.type,
      defaultAssetCurrency?.id,
      withdrawalDisabled,
      t,
      hasBalance,
      onFundsRequest,
      onExchange,
    ],
  );

  return (
    <InfoCard
      title={
        <div>
          <Typography variant="subtitle1">{title}</Typography>
        </div>
      }
      content={
        <Stack direction="column" spacing={5}>
          <div>
            {renderAmount()}
            <Typography variant="caption" color="textSecondary">
              {t('fields.balance')}
            </Typography>
          </div>

          <div>
            <StylizedMoney
              variant="subtitle2"
              value={asset.holdBalance}
              code
              assetCurrencyId={asset.assetCurrencyId}
            />
            <Typography variant="caption" color="textSecondary">
              {t('fields.hold_balance')}
            </Typography>
          </div>
        </Stack>
      }
      footer={renderFooter()}
    />
  );
};
