import { Stack } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tradersApi } from 'api';
import {
  CopyText,
  CrudPage,
  DataGridColumnDefinition,
  MailLink,
  StatusLabel,
  StylizedNumber,
  UserInfo,
} from 'components';
import {
  UserRole,
  FilterDefinitionType,
  QueryKey,
  TraderWorkStatus,
} from 'enums';
import { usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, Trader } from 'types';
import { traderUtils } from 'utils';

export const OperatorTradersPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Operator, {
    keyPrefix: 'pages.traders',
  });

  const queryResult = usePartialQuery(
    QueryKey.Traders,
    tradersApi.getAllAsRole(UserRole.Operator),
    { paginated: false },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Trader>[] => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyText text={item.id} truncateLength={10} />,
      },
      {
        header: t('fields.name'),
        valueGetter: (item) => (
          <Fragment>
            <UserInfo name={item.user?.name} id={item.user?.id} />
            {item.user?.email && <MailLink email={item.user?.email} />}
          </Fragment>
        ),
      },
      {
        header: t('fields.compensations'),
        valueGetter: (item) => (
          <Stack direction="row" spacing={1}>
            <StylizedNumber value={item.compensation} unit="%" />
            <span>{'/'}</span>
            <StylizedNumber value={item.payoutCompensation} unit="%" />
          </Stack>
        ),
      },
      {
        header: t('fields.work_status'),
        valueGetter: (item) => (
          <StatusLabel
            label={traderUtils.getWorkStatusLabel(item.workStatus)}
            status={item.workStatus}
          />
        ),
      },
    ],
    [t],
  );

  const filtersDefinitions: FilterDefinition<Trader>[] = [
    {
      label: t('filters.work_status'),
      name: 'workStatus',
      type: FilterDefinitionType.Enum,
      getDisplayName: traderUtils.getWorkStatusLabel,
      enum: TraderWorkStatus,
    },
  ];

  return (
    <CrudPage
      header={{ title: t('title') }}
      filters={{ filtersDefinitions }}
      table={{ queryResult, columns }}
    />
  );
};
