import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatusLabel, Timer } from 'components';
import { SHOW_TIMER_WHEN_LESS } from 'constants/order.constants';
import { OrderStatus } from 'enums';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

type Props = {
  order: PayinOrder;
};

export const OrderStatusColumn: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation',
  });

  return (
    <div>
      {[OrderStatus.New, OrderStatus.TraderConfirm].includes(order.status) &&
        order.statusAt && (
          <Timer
            endTime={moment(order.statusTimeoutAt).toDate()}
            showWhenLess={SHOW_TIMER_WHEN_LESS}
          />
        )}
      {order.status === OrderStatus.CustomerConfirm && order.statusAt && (
        <Timer
          endTime={moment(order.statusTimeoutAt).toDate()}
          showWhenLess={SHOW_TIMER_WHEN_LESS}
        />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <OrderStatusLabel status={order.status} />
        {order.confirmedByAutomation && (
          <Box ml={2}>
            <Tooltip title={t('confirmed')}>
              <CheckCircleOutlineIcon color="success" />
            </Tooltip>
          </Box>
        )}
        {order.conflictedByAutomation && (
          <Box ml={2}>
            <Tooltip title={t('conflict')}>
              <ErrorOutlineIcon color="warning" />
            </Tooltip>
          </Box>
        )}
      </Box>
    </div>
  );
};
