import { createContext } from 'react';

import { Shop, User } from 'types';

export type OrdersContextProps = {
  shops?: Shop[];
  traders?: User[];
  merchants?: User[];
};

export const OrdersContext = createContext<OrdersContextProps>({
  shops: [],
  traders: [],
  merchants: [],
});
