import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { orderAutomationApi } from 'api';
import { DataGridColumnDefinition } from 'components';
import {
  FilterDefinitionType,
  QueryKey,
  OrderAutomationFilterTypes,
  OrderAutomationReviewedStatusDetails,
} from 'enums';
import { usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  OrderAutomation,
  OrderAutomationFilters,
  FilterDefinition,
} from 'types';
import { formatUtils, orderAutomationUtils } from 'utils';

import { ManageOrderAutomationList } from '../ManageOrderAutomationList';

export const ErrorReviewedOrderAutomation: React.FC = () => {
  const { t: tColumns } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation.table.columns',
  });

  const { role } = useUser();

  const queryResult = usePartialQuery(
    QueryKey.ErrorReviewedOrderAutomation,
    orderAutomationApi.getMessagesPaginatedAsRole(
      role,
      OrderAutomationFilterTypes.ErrorReviewed,
    ),
    { useCachedCount: true },
  );

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<OrderAutomation>[] => [
      {
        header: tColumns('status_details'),
        valueGetter: (item) => (
          <Fragment>
            {item.statusDetails && (
              <div>
                {orderAutomationUtils.getStatusDetailsLabel(item.statusDetails)}
              </div>
            )}
          </Fragment>
        ),
      },
      {
        header: tColumns('reviewed'),
        valueGetter: (item) => (
          <Fragment>
            {item.reviewedBy?.name && <div>{item.reviewedBy.name}</div>}
            {item.reviewedAt && (
              <div>{formatUtils.formatDate(item.reviewedAt)}</div>
            )}
          </Fragment>
        ),
      },
    ],
    [tColumns],
  );

  const additionalFilters: FilterDefinition<OrderAutomationFilters>[] = useMemo(
    () => [
      {
        label: tColumns('status_details'),
        name: 'statusDetails',
        type: FilterDefinitionType.Enum,
        enum: OrderAutomationReviewedStatusDetails,
        getDisplayName: orderAutomationUtils.getStatusDetailsLabel,
      },
    ],
    [tColumns],
  );

  return (
    <ManageOrderAutomationList
      queryResult={queryResult}
      additionalColumns={additionalColumns}
      additionalFilters={additionalFilters}
    />
  );
};
