import { map, sortBy } from 'lodash';
import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { QueryKey } from 'enums';
import { Shop } from 'types';
import { formatUtils } from 'utils';

export function useShopsQuery<TError>(
  queryKey: QueryKey,
  queryFn: QueryFunction<Shop[], QueryKey>,
  options?:
    | Omit<
        UseQueryOptions<Shop[], TError, Shop[], QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined,
): UseQueryResult<Shop[]> {
  return useQuery(queryKey, queryFn, {
    ...options,
    select: (shops) =>
      map(
        sortBy(shops, (shop) => shop.archived),
        (shop) => ({
          ...shop,
          name: formatUtils.formatName(shop),
        }),
      ),
  });
}
