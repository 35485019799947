import { isNil, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { shopsApi } from 'api';
import { FormikSelect, FormikSelectProps } from 'components';
import { QueryKey } from 'enums';
import { useShopsQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Shop } from 'types';

type Props = {
  name: string;
  label?: string;
  shops?: Shop[];
} & Partial<FormikSelectProps>;

export const ShopSelect: React.FC<Props> = ({
  name,
  label,
  shops,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.shop_select',
  });
  const { role } = useUser();

  const queryResult = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
    {
      enabled: isNil(shops),
    },
  );

  const options = useMemo(
    () =>
      map(sortBy(shops || queryResult?.data, ['archived', 'name']), (shop) => ({
        value: shop.id,
        label: shop.name,
      })),
    [shops, queryResult],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
