import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
} from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { find, isFunction, map } from 'lodash';
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  CloseDialogResult,
  ConfirmButton,
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  Dialog,
  DialogProps,
  PaginationWrapper,
} from 'components';
import { TranslationNamespace } from 'i18n';
import { PaginatedData } from 'types';

enum ActionType {
  Details,
  Remove,
  Restore,
  Custom,
}

type DetailsAction = {
  type: ActionType.Details;
  path?: string;
  renderDialog?: (props: DialogProps) => ReactNode;
  onUpdate?: (item: any, data: any, closeDialog: () => void) => void;
  onClick?: (item: any) => void;
};
type RemoveAction = {
  type: ActionType.Remove;
  disabled?: boolean;
  renderDialogContent?: (item: any) => ReactNode;
  onRemove?: (item: any, options: { close: () => void }) => void;
  onClick?: (item: any) => void;
};
type RestoreAction = {
  type: ActionType.Restore;
  onRestore?: (item: any) => void;
};
type CustomAction<T = any> = {
  type: ActionType.Custom;
  hidden?: boolean;
  render: (item: T) => ReactNode;
};

type Action<T = any> =
  | DetailsAction
  | RemoveAction
  | RestoreAction
  | CustomAction<T>;

type Props = {
  queryResult:
    | UseQueryResult<unknown[], unknown>
    | UseQueryResult<PaginatedData<unknown>, unknown>;
  columns: DataGridColumnDefinition[];
  actions?: Action[];
  hideActions?: boolean;
  paginated?: boolean;
  formatItems?: (items: any[]) => any[];
  onRowClick?: (item?: any) => void;
  selectedRowIndex?: number;
};

export { ActionType as CrudTableActionType };

export type { Action as CrudTableAction };

export type CrudTableProps = Props;

export const CrudTable: React.FC<Props> = ({
  queryResult,
  columns,
  actions,
  hideActions,
  paginated,
  formatItems: formatItemsProp,
  onRowClick,
  selectedRowIndex,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.crud_table',
  });
  const navigate = useNavigate();

  const removeAction = useMemo(
    () => find(actions, { type: ActionType.Remove }) as RemoveAction,
    [actions],
  );

  const detailsAction = useMemo(
    () => find(actions, { type: ActionType.Details }) as DetailsAction,
    [actions],
  );

  const restoreAction = useMemo(
    () => find(actions, { type: ActionType.Restore }) as RestoreAction,
    [actions],
  );

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: any;
  }>({
    open: false,
  });

  const [detailsDialogProps, setDetailsDialogProps] = useState<{
    open: boolean;
    data?: any;
  }>({
    open: false,
  });

  const handleDetailsClick = useCallback(
    (item: any, action: DetailsAction) => {
      if (action.path) {
        return navigate(generatePath(action.path, { id: item.id }));
      }
      if (action.renderDialog) {
        return setDetailsDialogProps({ open: true, data: item });
      }
      if (action.onClick) {
        return action.onClick(item);
      }
    },
    [navigate],
  );

  const handleRemoveClick = useCallback((item: any, action: RemoveAction) => {
    if (action.onClick) {
      return action.onClick(item);
    }
    if (action.onRemove) {
      setConfirmRemoveDialogProps({ open: true, data: item });
    }
  }, []);

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<any>) => {
      if (ok && data) {
        removeAction?.onRemove?.(data, {
          close: () => setConfirmRemoveDialogProps({ open: false }),
        });
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [removeAction],
  );

  const closeDetailsDialog = useCallback(() => {
    setDetailsDialogProps({ open: false });
  }, []);

  const handleCloseDetailsDialog = useCallback(
    (data: CloseDialogResult) => {
      if (data.ok && data.data) {
        detailsAction?.onUpdate?.(
          detailsDialogProps.data,
          data.data,
          closeDetailsDialog,
        );
      } else {
        setDetailsDialogProps({ open: false });
      }
    },
    [closeDetailsDialog, detailsAction, detailsDialogProps.data],
  );

  const handleRestoreClick = useCallback(
    (item: any) => {
      restoreAction?.onRestore?.(item);
    },
    [restoreAction],
  );

  const getActionElement = useCallback(
    (item: any, action: Action, index: number) => {
      switch (action.type) {
        case ActionType.Remove:
          return (
            <IconButton
              key={index}
              color="error"
              title={t('actions.remove')}
              onClick={(e) => {
                if (onRowClick) {
                  e.preventDefault();
                  e.stopPropagation();
                }

                handleRemoveClick(item, action);
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        case ActionType.Details:
          return (
            <IconButton
              key={index}
              color="primary"
              title={t('actions.details')}
              onClick={(e) => {
                if (onRowClick) {
                  e.preventDefault();
                  e.stopPropagation();
                }

                handleDetailsClick(item, action);
              }}
            >
              <EditIcon />
            </IconButton>
          );
        case ActionType.Restore:
          return (
            <ConfirmButton
              key={index}
              dialog={{
                children: t('actions.restore_dialog.description'),
              }}
              onConfirm={() => {
                handleRestoreClick(item);
              }}
            >
              {({ onClick }) => (
                <IconButton
                  color="primary"
                  title={t('actions.restore')}
                  onClick={(e) => {
                    if (onRowClick) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    onClick();
                  }}
                >
                  <RestoreFromTrashIcon />
                </IconButton>
              )}
            </ConfirmButton>
          );
        case ActionType.Custom:
          return action.hidden ? null : (
            <Fragment key={index}>{action.render(item)}</Fragment>
          );
      }
    },
    [t, handleDetailsClick, handleRemoveClick, handleRestoreClick, onRowClick],
  );

  const resultColumns = useMemo(() => {
    const result = [...columns];
    if (actions && !hideActions) {
      result.push({
        header: t('columns.actions'),
        valueClassName: 'tw-w-[120px]',
        valueGetter: (item) => (
          <Stack direction="row" spacing={1}>
            {map(actions, (action, i) => getActionElement(item, action, i))}
          </Stack>
        ),
      });
    }
    return result;
  }, [t, actions, columns, hideActions, getActionElement]);

  const formatItems = useCallback(
    (items: unknown[]) => {
      if (isFunction(formatItemsProp)) {
        return formatItemsProp(items);
      }
      return items;
    },
    [formatItemsProp],
  );

  return (
    <Fragment>
      {!paginated && (
        <DataWrapper queryResult={queryResult}>
          <DataGrid
            columns={resultColumns}
            data={formatItems(
              (queryResult as UseQueryResult<unknown[], unknown>).data || [],
            )}
            onRowClick={onRowClick}
            selectedRowIndex={selectedRowIndex}
          ></DataGrid>
        </DataWrapper>
      )}

      {paginated && (
        <PaginationWrapper
          queryResult={
            queryResult as UseQueryResult<PaginatedData<unknown>, unknown>
          }
        >
          <DataGrid
            columns={resultColumns}
            data={formatItems(
              (queryResult as UseQueryResult<PaginatedData<unknown>, unknown>)
                .data?.items || [],
            )}
            onRowClick={onRowClick}
            selectedRowIndex={selectedRowIndex}
          />
        </PaginationWrapper>
      )}

      <Dialog
        title={t('actions.remove_dialog.title')}
        onClose={handleCloseRemoveDialog}
        disabled={removeAction?.disabled}
        {...confirmRemoveDialogProps}
      >
        <Fragment>
          {confirmRemoveDialogProps.data &&
            (removeAction?.renderDialogContent ? (
              removeAction?.renderDialogContent(confirmRemoveDialogProps.data)
            ) : (
              <div>{t('actions.remove_dialog.description')}</div>
            ))}
        </Fragment>
      </Dialog>
      {detailsAction?.renderDialog?.({
        ...detailsDialogProps,
        onClose: handleCloseDetailsDialog,
      })}
    </Fragment>
  );
};
