import { Button } from '@mui/material';
import { xor } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { fundsRequestsApi } from 'api';
import {
  CloseDialogResult,
  DataGridColumnDefinition,
  PageHeader,
} from 'components';
import { FUNDS_REQUEST_TRANSACTION_IN_PROGRESS_STATUSES } from 'constants/funds-requests.constants';
import {
  FundsRequestCompletionType,
  FundsRequestStatus,
  OperationType,
  QueryKey,
} from 'enums';
import { FundsRequests } from 'features';
import { useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FundsRequest, Shop, User } from 'types';

import {
  FundsRequestCompletionDepositDialog,
  FundRequestCompletionData,
} from './FundsRequstCompletionDepositDialog';

type Props = {
  hideTitle?: boolean;
  users?: User[];
  shops?: Shop[];
};

export const ProcessFundsRequests: React.FC<Props> = ({
  hideTitle,
  users,
  shops,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });

  const queryResult = usePartialQuery(
    QueryKey.FundsRequests,
    fundsRequestsApi.getAllActivePaginated,
  );

  const [processInProgress, setProcessInProgress] = useState<string[]>([]);
  const [completeDepositDialogProps, setCompleteDepositDialogProps] = useState<{
    open: boolean;
    data?: FundRequestCompletionData;
  }>({
    open: false,
  });

  const queryClient = useQueryClient();

  const onProcessSuccess = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.FundsRequests);
  }, [queryClient]);

  const { mutate: complete } = useMutation(fundsRequestsApi.processComplete, {
    onSuccess: onProcessSuccess,
  });

  const { mutate: cancel } = useMutation(fundsRequestsApi.processCancel, {
    onSuccess: onProcessSuccess,
  });

  const toggleProcessInProgress = useCallback((item: FundsRequest) => {
    setProcessInProgress((prev) => xor(prev, [item.id]));
  }, []);

  const handleCancel = useCallback(
    (item: FundsRequest) => {
      setCompleteDepositDialogProps({
        open: true,
        data: {
          item,
          completionType: FundsRequestCompletionType.Reject,
        },
      });
    },
    [setCompleteDepositDialogProps],
  );

  const handleComplete = useCallback(
    (item: FundsRequest) => {
      setCompleteDepositDialogProps({
        open: true,
        data: {
          item,
          completionType: FundsRequestCompletionType.Approve,
        },
      });
    },
    [setCompleteDepositDialogProps],
  );

  const handleCompleteDepositDialog = useCallback(
    ({ ok, data }: CloseDialogResult<FundRequestCompletionData>) => {
      if (ok && data) {
        toggleProcessInProgress(data.item);
        if (data.completionType === FundsRequestCompletionType.Reject) {
          cancel(data.item, {
            onSettled: () => {
              toggleProcessInProgress(data.item);
              setCompleteDepositDialogProps({ open: false });
            },
          });
        } else if (data.completionType === FundsRequestCompletionType.Approve) {
          complete(
            {
              ...data.item,
              ...(data.item.operationType === OperationType.Withdrawal && {
                hash: data.hash,
                sendTransaction: data.sendTransaction,
              }),
            },
            {
              onSettled: () => {
                toggleProcessInProgress(data.item);
                setCompleteDepositDialogProps({ open: false });
              },
            },
          );
        }
      } else {
        setCompleteDepositDialogProps({ open: false });
      }
    },
    [cancel, complete, toggleProcessInProgress],
  );

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<FundsRequest>[] => [
      {
        header: t('funds_requests.fields.actions'),
        valueClassName: 'tw-min-w-[240px]',
        valueGetter: (item) => {
          const isTransactionInProgressStatus =
            FUNDS_REQUEST_TRANSACTION_IN_PROGRESS_STATUSES.includes(
              item.status,
            );

          const isNew = item.status === FundsRequestStatus.New;

          if (!isNew && !isTransactionInProgressStatus) {
            return null;
          }

          return (
            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
              {item.status === FundsRequestStatus.New ? (
                <Button
                  variant="outlined"
                  size="small"
                  disabled={processInProgress.includes(item.id)}
                  onClick={() => handleComplete(item)}
                >
                  {t('process_funds_requests.approve')}
                </Button>
              ) : (
                <div />
              )}
              <Button
                variant="outlined"
                color="error"
                size="small"
                disabled={processInProgress.includes(item.id)}
                onClick={() => handleCancel(item)}
              >
                {t('process_funds_requests.reject')}
              </Button>
            </div>
          );
        },
      },
    ],
    [processInProgress, t, handleCancel, handleComplete],
  );

  return (
    <Fragment>
      {!hideTitle && <PageHeader title={t('process_funds_requests.title')} />}
      <FundsRequests
        queryResult={queryResult}
        additionalColumns={additionalColumns}
        users={users}
        shops={shops}
      />
      <FundsRequestCompletionDepositDialog
        {...completeDepositDialogProps}
        onClose={handleCompleteDepositDialog}
      ></FundsRequestCompletionDepositDialog>
    </Fragment>
  );
};
