import { env } from 'config';
import { OrderType } from 'enums';
import {
  QueryParams,
  SummaryStatistic,
  PaginatedData,
  RequisitesWithStatistic,
  Requisites,
  PayoutRequisites,
  RequisitesStatistic,
} from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/statistics`;

const getTimezoneOffset = () => new Date().getTimezoneOffset();

const getAgentsSummary =
  (orderType: OrderType) =>
  async (params: QueryParams): Promise<SummaryStatistic> =>
    (
      await apiUtils.getWithQueryParams(
        `${url}/${orderType}/agents/summary`,
        params,
      )
    ).data;

const getSummary =
  (orderType: OrderType) =>
  async (params: QueryParams): Promise<SummaryStatistic> =>
    (await apiUtils.getWithQueryParams(`${url}/${orderType}/summary`, params))
      .data;

const getDailySummary =
  (orderType: OrderType) =>
  async (params: QueryParams): Promise<Record<string, SummaryStatistic>> =>
    (
      await apiUtils.getWithQueryParams(`${url}/${orderType}/summary/daily`, {
        ...params,
        tzOffset: getTimezoneOffset(),
      })
    ).data;

const getRequisitesPaginated =
  (orderType: OrderType) =>
  async (
    params: QueryParams,
  ): Promise<
    PaginatedData<RequisitesWithStatistic<Requisites | PayoutRequisites>>
  > =>
    (
      await apiUtils.getWithQueryParams(
        `${url}/${orderType}/requisites`,
        params,
      )
    ).data;

const getDailyRequisitesPaginated =
  (orderType: OrderType, id: string) =>
  async (
    params: QueryParams,
  ): Promise<PaginatedData<Record<string, RequisitesStatistic>>> =>
    (
      await apiUtils.getWithQueryParams(
        `${url}/${orderType}/requisites/${id}/daily`,
        {
          ...params,
          tzOffset: getTimezoneOffset(),
        },
      )
    ).data;

export const statisticsApi = {
  getAgentsSummary,
  getSummary,
  getDailySummary,
  getRequisitesPaginated,
  getDailyRequisitesPaginated,
};
