import { Stack } from '@mui/material';
import { findIndex } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { shopsApi, usersApi } from 'api';
import {
  CrudPage,
  CrudTableAction,
  CrudTableActionType,
  DataGridColumnDefinition,
  ExternalLink,
  SidebarLayout,
  SidebarSize,
  StylizedNumber,
  dataGridColumns,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey } from 'enums';
import { useCurrencies, useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Shop } from 'types';

import { ShopDetails } from '../ShopDetails';
import { ShopStatusLabel } from '../ShopStatusLabel';

const queryKey = QueryKey.Shops;

type Props = {
  title?: string;
  archived?: boolean;
};

export const ShopList: React.FC<Props> = ({ title, archived }) => {
  const navigate = useNavigate();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.shops_list.table.columns',
  });
  const { t: tRemoveDialog } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.shops_list.remove_dialog',
  });

  const { getCurrenciesCodes } = useCurrencies();

  const { role, isAdmin, isTechOperator, isMerchant, isAdminOrTechOperator } =
    useUser();

  const queryClient = useQueryClient();
  const queryResult = useQuery(
    queryKey,
    archived
      ? shopsApi.getAllArchiveAsRole(role)
      : shopsApi.getAllNotArchiveAsRole(role),
  );
  const { data: shops = [] } = queryResult || [];
  const usersQuery = useQuery(QueryKey.Users, usersApi.getAll, {
    enabled: isAdminOrTechOperator,
  });

  const { mutate: archive } = useMutation(shopsApi.archive, {
    onSuccess: (data) => {
      if (data.id === selectedShop?.id) {
        setSelectedShop(null);
      }
      queryClient.invalidateQueries(queryKey);
    },
    notifierType: 'remove',
  });

  const { mutate: restore } = useMutation(shopsApi.restore, {
    onSuccess: (data) => {
      if (data.id === selectedShop?.id) {
        setSelectedShop(null);
      }
      queryClient.invalidateQueries(queryKey);
    },
    notifierType: 'execute',
  });

  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const handleOpenShop = useCallback((shop: Shop) => setSelectedShop(shop), []);
  const handleCloseShop = useCallback(() => setSelectedShop(null), []);

  const shopDetailsPage = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.SHOP_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.SHOP_DETAILS;
    } else if (isMerchant) {
      return ROUTE_PATH.MERCHANT.SHOP_DETAILS;
    }
  }, [isAdmin, isMerchant, isTechOperator]);

  const handleCreateOrEdit = useCallback(
    (id: string) => navigate(generatePath(shopDetailsPage!, { id })),
    [shopDetailsPage, navigate],
  );

  const notArchivedActions = useMemo(
    (): CrudTableAction<Shop>[] => [
      {
        type: CrudTableActionType.Details,
        onClick: (item) => handleCreateOrEdit(item.id),
      },
      {
        type: CrudTableActionType.Remove,
        renderDialogContent: (item) => (
          <div>
            {tRemoveDialog('confirm')}
            <div>{item.name}</div>
          </div>
        ),
        onRemove: (item, { close }) =>
          archive(item.id, {
            onSuccess: close,
          }),
      },
    ],
    [archive, handleCreateOrEdit, tRemoveDialog],
  );

  const archivedActions = useMemo(
    (): CrudTableAction<Shop>[] => [
      {
        type: CrudTableActionType.Restore,
        onRestore: (item) => {
          restore(item.id);
        },
      },
    ],
    [restore],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Shop>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('name'),
        valueGetter: (item) => item.name || '',
      },
      {
        header: t('website'),
        valueGetter: (item) => (
          <Fragment>
            <ExternalLink href={item.webSiteUrl} />
            {item.webSiteIp && <div>{item.webSiteIp}</div>}
          </Fragment>
        ),
      },
      {
        header: t('currencies'),
        valueGetter: (item) => getCurrenciesCodes(item),
      },
      {
        header: t('merchant_name'),
        valueGetter: (item) => item.user?.name || '',
        hidden: !isAdminOrTechOperator,
      },
      {
        header: t('commission'),
        valueGetter: (item) => (
          <Stack direction="row" spacing={1}>
            <StylizedNumber value={item.commission} unit="%" />
            <span>{'/'}</span>
            <StylizedNumber value={item.payoutCommission} unit="%" />
          </Stack>
        ),
      },
      {
        header: t('status'),
        valueGetter: (item) => <ShopStatusLabel status={item.status} />,
      },
      {
        header: t('groups'),
        multiValueRenderer: {
          itemsGetter: (item) => item.groups,
          valueGetter: (group) => <div key={group.id}>{group?.name}</div>,
        },
        hidden: !isAdminOrTechOperator,
      },
    ],
    [getCurrenciesCodes, isAdminOrTechOperator, t],
  );

  return (
    <Fragment>
      <SidebarLayout
        sidebarProps={{
          size: SidebarSize.md,
          open: !!selectedShop,
          onClose: handleCloseShop,
          title: selectedShop && (
            <Fragment>
              {selectedShop.name}
              <ShopStatusLabel sx={{ ml: 1 }} status={selectedShop.status} />
            </Fragment>
          ),
          content: (
            <ShopDetails
              shop={selectedShop}
              users={usersQuery.data}
              adminView={isAdminOrTechOperator}
            />
          ),
        }}
      >
        <CrudPage
          header={{
            hide: archived,
            title,
            ...(isMerchant && {
              rightContentButton: {
                onClick: () => handleCreateOrEdit(NEW_ID),
              },
            }),
          }}
          table={{
            queryResult,
            columns,
            onRowClick: handleOpenShop,
            selectedRowIndex: findIndex(shops, {
              id: selectedShop?.id,
            }),
            actions: archived ? archivedActions : notArchivedActions,
          }}
        />
      </SidebarLayout>
    </Fragment>
  );
};
