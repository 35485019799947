import { find } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { blockedCustomersApi, merchantsApi, shopsApi } from 'api';
import {
  CloseDialogResult,
  CrudPage,
  DataGridColumnDefinition,
  dataGridColumns,
  Dialog,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useShopsQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { BlockedCustomer, FilterDefinition } from 'types';
import { formatUtils, formUtils } from 'utils';

type BlockedCustomersFilters = {
  merchantId: string;
  shopId: string;
  customerId: string;
};

export const BlockedCustomers: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.blocked_customers',
  });
  const { t: tCommon } = useTranslation();
  const { role } = useUser();
  const queryClient = useQueryClient();
  const queryResult = usePartialQuery(
    QueryKey.BlockedCustomers,
    blockedCustomersApi.getAllPaginatedAsRole(role),
  );

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: BlockedCustomer;
  }>({
    open: false,
  });

  const queryResultMerchants = useQuery(QueryKey.Merchants, () =>
    merchantsApi.getAllAsRole(role)(),
  );

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  const { mutate: removeBlockedCustomer, isLoading: isRemoveLoading } =
    useMutation(blockedCustomersApi.removeAsRole(role), {
      onSuccess: () => {
        setConfirmRemoveDialogProps({ open: false });
        queryClient.invalidateQueries(QueryKey.BlockedCustomers);
      },
    });

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<BlockedCustomer>) => {
      if (ok && data) {
        removeBlockedCustomer(data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [removeBlockedCustomer],
  );

  const handleRemove = useCallback((data: BlockedCustomer) => {
    setConfirmRemoveDialogProps({ open: true, data });
  }, []);

  const columns = useMemo(
    (): DataGridColumnDefinition<BlockedCustomer>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.shop'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.merchant.user.name}</div>
            <div>{formatUtils.formatName(item.shop)}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.id'),
        valueKey: 'customerId',
      },
      {
        header: t('fields.ip'),
        valueKey: 'customerIp',
      },
      {
        header: t('fields.fingerprint'),
        valueKey: 'customerFingerprint',
      },
      {
        header: t('fields.rules'),
        valueGetter: (item) => (
          <Fragment>
            <div>{`${t('fields.duration')}: ${item.duration} ${tCommon(
              'suffixes.minutes',
            )}`}</div>
            <div>{`${t('fields.count')}: ${item.count}`}</div>
            <div>{`${t('fields.period')}: ${item.period} ${tCommon(
              'suffixes.minutes',
            )}`}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.until_at'),
        valueKey: 'untilAt',
        valueFormatter: formatUtils.formatDate,
      },
      dataGridColumns.getActionsColumn({
        handleRemove,
      }),
    ],
    [t, tCommon, handleRemove],
  );

  const filtersDefinitions: FilterDefinition<BlockedCustomersFilters>[] =
    useMemo(
      () => [
        {
          label: tCommon('filters.merchant'),
          name: 'merchantId',
          type: FilterDefinitionType.Select,
          getDisplayName: (merchantId: string) =>
            find(queryResultMerchants.data, { id: merchantId })?.user?.name,
          options: formUtils.getOptions(
            queryResultMerchants?.data || [],
            'user.name' as any,
          ),
        },
        {
          label: tCommon('filters.shop'),
          name: 'shopId',
          type: FilterDefinitionType.Shop,
          users: queryResultShops.data,
          getDisplayName: (shopId: string) =>
            find(queryResultShops.data, { id: shopId })?.name,
        },
        {
          label: tCommon('filters.id'),
          name: 'customerId',
          type: FilterDefinitionType.Text,
        },
      ],
      [tCommon, queryResultMerchants.data, queryResultShops.data],
    );

  return (
    <Fragment>
      <CrudPage
        header={{ title: t('title') }}
        table={{ queryResult, columns, paginated: true }}
        filters={{ filtersDefinitions }}
      />
      <Dialog
        title={t('remove_dialog.title')}
        onClose={handleCloseRemoveDialog}
        disabled={isRemoveLoading}
        {...confirmRemoveDialogProps}
      >
        <Fragment>
          <div className="tw-mb-4">{t('remove_dialog.description')}</div>
          {confirmRemoveDialogProps.data && (
            <Fragment>
              <div className="tw-mb-2">
                <div>{confirmRemoveDialogProps.data.merchant.user.name}</div>
                <div>
                  {formatUtils.formatName(confirmRemoveDialogProps.data.shop)}
                </div>
              </div>
              <div>
                {confirmRemoveDialogProps.data.customerId && (
                  <div>{`${t('fields.id')}: ${
                    confirmRemoveDialogProps.data.customerId
                  }`}</div>
                )}
                {confirmRemoveDialogProps.data.customerIp && (
                  <div>{`${t('fields.ip')}: ${
                    confirmRemoveDialogProps.data.customerIp
                  }`}</div>
                )}
                {confirmRemoveDialogProps.data.customerIp && (
                  <div>{`${t('fields.fingerprint')}: ${
                    confirmRemoveDialogProps.data.customerFingerprint
                  }`}</div>
                )}
                <div>{`${t('fields.until_at')}: ${formatUtils.formatDate(
                  confirmRemoveDialogProps.data.untilAt,
                )}`}</div>
              </div>
            </Fragment>
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};
