import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import { filter, isEmpty } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { internalWalletsApi } from 'api';
import {
  CopyText,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  Money,
  dataGridColumns,
} from 'components';
import { QueryKey } from 'enums';
import { WalletInformationIcon, WalletResources } from 'features/wallets';
import { useCurrencies, useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalWallet, WalletResourcesData } from 'types';
import { formatUtils } from 'utils';

import { PlatformWalletDetailsDialog } from './PlatformWalletDetailsDialog';

export const PlatformWallets: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets',
  });

  const { getDefaultAssetCurrency } = useCurrencies();

  const [resources, setResources] = useState<{
    [id: string]: WalletResourcesData;
  }>({});
  const [resourcesLoading, setResourcesLoading] = useState<string[]>([]);

  const queryResult = usePartialQuery(
    QueryKey.InternalPlatformWallets,
    internalWalletsApi.getAllOfPlatformPaginated,
  );

  const { mutate: getResources } = useMutation(
    internalWalletsApi.getResources,
    {
      onMutate: (itemId: string) => {
        setResourcesLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setResources((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setResourcesLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'execute',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalWallet>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.creator'),
        valueGetter: (item) => item.platformWalletConfig?.createdBy?.name,
      },
      {
        header: t('fields.address'),
        valueGetter: (item) => (
          <Fragment>
            <div className="tw-uppercase">{item.network}</div>
            <CopyText text={item.address} />
          </Fragment>
        ),
      },
      {
        header: (
          <div className="tw-flex tw-items-center">
            <div>{t('fields.limit')}</div>
            <Tooltip title={t('fields.limit_tooltip')}>
              <HelpIcon sx={{ ml: 1 }} fontSize="small" color="secondary" />
            </Tooltip>
          </div>
        ),
        valueGetter: (item) => (
          <Money
            value={item.platformWalletConfig?.desirableMaxBalanceLimit || 0}
            assetCurrencyId={getDefaultAssetCurrency()?.id}
            symbol
          />
        ),
      },
      {
        header: t('fields.operations'),
        valueGetter: (item) => (
          <Fragment>
            <div>{`${t('fields.deposit')}: ${formatUtils.formatBoolean(
              item.platformWalletConfig?.deposit,
            )}`}</div>
            <div>{`${t('fields.withdrawal')}: ${formatUtils.formatBoolean(
              item.platformWalletConfig?.withdrawal,
            )}`}</div>
            <div>{`${t(
              'fields.account_activation',
            )}: ${formatUtils.formatBoolean(
              item.platformWalletConfig?.accountActivation,
            )}`}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.resources'),
        valueGetter: (item) => {
          if (resourcesLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <WalletResources
              resources={resources[item.id]}
              disabled={!isEmpty(resourcesLoading)}
              getResources={() => getResources(item.id)}
            />
          );
        },
      },
    ],
    [t, getDefaultAssetCurrency, resourcesLoading, resources, getResources],
  );

  return (
    <Fragment>
      <CrudTable
        columns={columns}
        queryResult={queryResult}
        paginated
        actions={[
          {
            type: CrudTableActionType.Details,
            renderDialog: PlatformWalletDetailsDialog,
          },
          {
            type: CrudTableActionType.Custom,
            render: (item) => <WalletInformationIcon item={item} />,
          },
        ]}
      />
    </Fragment>
  );
};
