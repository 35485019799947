import { Warning } from '@mui/icons-material';
import { find, forEach, isEmpty, map } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { groupsApi } from 'api';
import { Math } from 'classes';
import {
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  PaginationWrapper,
  QueryFilters,
  StylizedNumber,
} from 'components';
import { FilterDefinitionType, OrderType, QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AgentInfo, FilterDefinition, Group, Shop, Trader, User } from 'types';

type Props = {
  orderType: OrderType;
  isLoading: boolean;
  users?: User[];
  traders?: Trader[];
  shops?: Shop[];
};

type GroupsFilters = {
  name: string;
  groupId: string;
  shopId: string;
  traderId: string;
  payin: boolean;
  payout: boolean;
};

type CashbackWithRelations = {
  id: string;
  group: Group;
  shop?: Shop;
  trader?: Trader;
  user?: User;
};

export const GroupsCashback: React.FC<Props> = ({
  users,
  traders,
  shops,
  orderType,
  isLoading,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.cashback',
  });
  const { t: tGroups } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.groups',
  });
  const filtersDefinitions: FilterDefinition<GroupsFilters>[] = useMemo(
    () => [
      {
        label: tGroups('filters.id'),
        name: 'groupId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: tGroups('filters.name'),
        name: 'name',
        type: FilterDefinitionType.Text,
      },
      {
        label: tGroups('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.Trader,
        traders,
        getDisplayName: (traderId: string) =>
          find(traders, { id: traderId })?.user?.name,
      },
      {
        label: tGroups('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        users: shops,
        getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
      },
    ],
    [shops, tGroups, traders],
  );

  const queryResult = usePartialQuery(
    [QueryKey.Groups, orderType],
    groupsApi.getForOrderTypePaginated(orderType),
  );

  const getAgentName = useCallback(
    (agentId: string) => {
      const agentUser = find(users, (user) => user.id === agentId);
      return agentUser?.name || agentId;
    },
    [users],
  );

  const getAgentCommission = useCallback(
    (agent: AgentInfo) =>
      orderType === OrderType.Payin
        ? agent.payinCompensationPercentage || 0
        : agent.payoutCompensationPercentage || 0,
    [orderType],
  );

  const getAgentsCommission = useCallback(
    (agents?: AgentInfo[]): number => {
      const math = new Math(0);
      forEach(agents, (agent) => {
        math.plus(getAgentCommission(agent));
      });
      return math.value;
    },
    [getAgentCommission],
  );

  const getTraderCompensation = useCallback(
    (trader?: Trader): number =>
      orderType === OrderType.Payin
        ? trader?.compensation || 0
        : trader?.payoutCompensation || 0,
    [orderType],
  );

  const getShopCommission = useCallback(
    (shop?: Shop) =>
      orderType === OrderType.Payin
        ? shop?.commission || 0
        : shop?.payoutCommission || 0,
    [orderType],
  );

  const getPlatformCommission = useCallback(
    (item: CashbackWithRelations): number => {
      const math = new Math(getShopCommission(item.shop));
      math.minus(
        getAgentsCommission(item.shop?.agents),
        getTraderCompensation(item.trader),
        getAgentsCommission(item.user?.agents),
      );
      return math.value;
    },
    [getAgentsCommission, getShopCommission, getTraderCompensation],
  );

  const renderAgentsInfo = useCallback(
    (agents?: AgentInfo[]) => {
      if (isEmpty(agents)) {
        return null;
      }

      return (
        <Fragment>
          {map(agents, (agent: AgentInfo) => (
            <div className="tw-flex" key={agent.userId}>
              <span className="tw-pr-2">{getAgentName(agent.userId)}</span>
              <StylizedNumber value={getAgentCommission(agent)} unit="%" />
            </div>
          ))}
          <div className="tw-mt-2 tw-flex">
            <div className="tw-mr-2">{t('labels.total')}</div>
            <StylizedNumber value={getAgentsCommission(agents)} unit="%" />
          </div>
        </Fragment>
      );
    },
    [getAgentCommission, getAgentName, getAgentsCommission, t],
  );

  const columns: DataGridColumnDefinition<CashbackWithRelations>[] = useMemo(
    () => [
      {
        header: '',
        valueGetter: (item) => {
          if (getPlatformCommission(item) < 0) {
            return <Warning color="warning" />;
          }
        },
      },
      {
        header: t('columns.group'),
        valueGetter: (item) => <div>{item.group.name}</div>,
      },
      {
        header: t('columns.shop'),
        valueGetter: (item) =>
          item.shop ? (
            <div className="tw-flex">
              <div className="tw-mr-2">{item.shop.name}</div>
              <StylizedNumber value={getShopCommission(item.shop)} unit="%" />
            </div>
          ) : null,
      },
      {
        header: t('columns.shop_agents'),
        valueGetter: (item) => renderAgentsInfo(item.shop?.agents),
      },
      {
        header: t('columns.trader'),
        valueGetter: (item) =>
          item.user ? (
            <div className="tw-flex">
              <div className="tw-pr-2">{item.user.name}</div>
              <StylizedNumber
                value={getTraderCompensation(item.trader)}
                unit="%"
              />
            </div>
          ) : null,
      },
      {
        header: t('columns.trader_agents'),
        valueGetter: (item) => renderAgentsInfo(item.user?.agents),
      },
      {
        header: t('columns.platform_commission'),
        valueGetter: (item) => (
          <StylizedNumber value={getPlatformCommission(item)} unit="%" />
        ),
      },
    ],
    [
      getPlatformCommission,
      getShopCommission,
      getTraderCompensation,
      renderAgentsInfo,
      t,
    ],
  );

  const data: CashbackWithRelations[] = useMemo(() => {
    const cashbackData: CashbackWithRelations[] = [];
    forEach(queryResult.data?.items, (group) => {
      forEach(group.shops, (_shop) => {
        const shop = find(shops, { id: _shop.id });
        forEach(group.traders, ({ id: traderId }) => {
          const trader = find(traders, (trader) => trader.id === traderId);
          const user = find(users, (user) => trader?.userId === user.id);
          cashbackData.push({
            id: `${group.id}_${_shop.id}_${traderId}`,
            group,
            shop,
            user,
            trader,
          });
        });
      });
    });
    return cashbackData;
  }, [queryResult, shops, users, traders]);

  return (
    <Fragment>
      <QueryFilters filtersDefinitions={filtersDefinitions} />
      <DataWrapper isLoading={isLoading}>
        <PaginationWrapper
          queryResult={queryResult}
          ignoreState={{ refetchLoading: true }}
        >
          <DataGrid columns={columns} data={data} />
        </PaginationWrapper>
      </DataWrapper>
    </Fragment>
  );
};
