import { useQuery } from 'react-query';

import { assetsApi, shopsApi } from 'api';
import { QueryKey } from 'enums';
import { InternalTransfersPage } from 'features/internal-transfers';
import { useShopsQuery } from 'hooks';
export const MerchantInternalTransfersPage: React.FC = () => {
  const queryResultAssets = useQuery(
    QueryKey.MerchantsAssets,
    assetsApi.getMerchantAllMy,
  );

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsMerchant,
  );

  return (
    <InternalTransfersPage
      queryResultShops={queryResultShops}
      queryResultAssets={queryResultAssets}
    />
  );
};
