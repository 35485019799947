import { env } from 'config';
import { AssetBalanceHistory, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/asset-balance-history`;

const getPaginatedByUrl =
  (url: string) =>
  async (params: QueryParams): Promise<PaginatedData<AssetBalanceHistory>> =>
    (await apiUtils.getWithQueryParams(url, params)).data;

const getAllPaginated = getPaginatedByUrl(url);

const getMyPaginated = getPaginatedByUrl(`${url}/my`);

const getAllMerchantsPaginated = getPaginatedByUrl(`${url}/merchants`);

export const assetBalanceHistoryApi = {
  getAllPaginated,
  getMyPaginated,
  getAllMerchantsPaginated,
};
