import { Stack } from '@mui/material';
import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { groupsApi, tradersApi } from 'api';
import {
  DataGridColumnDefinition,
  MailLink,
  StatusLabel,
  StylizedNumber,
  UserInfo,
  dataGridColumns,
  CrudPage,
  CopyTextId,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey, TraderWorkStatus } from 'enums';
import { useMeta, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, Group, Trader, TraderFilters } from 'types';
import { formatUtils, formUtils, traderUtils } from 'utils';

export const TradersPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.traders',
  });
  const { t: tCommon } = useTranslation();

  const { payoutMaxActiveOrders } = useMeta();

  const { role, isAdmin, isTechOperator } = useUser();
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const traderDetailsRoutePath = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.TRADER_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.TRADER_DETAILS;
    }
  }, [isAdmin, isTechOperator]);

  const navigate = useNavigate();
  const queryResult = usePartialQuery(
    QueryKey.Traders,
    tradersApi.getAllAsRole(role),
    { paginated: false },
  );

  const queryResultGroups = useQuery(QueryKey.Groups, groupsApi.getAll, {
    enabled: canManage,
  });

  const queryResultTraders = useQuery([QueryKey.Traders, 'all'], () =>
    tradersApi.getAllAsRole(role)(),
  );

  const handleEditClick = useCallback(
    (traderId: string) => {
      const url = generatePath(traderDetailsRoutePath!, {
        id: traderId,
      });
      navigate(url);
    },
    [traderDetailsRoutePath, navigate],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Trader>[] => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.name'),
        valueGetter: (item) => (
          <Fragment>
            <UserInfo name={item.user?.name} id={item.user?.id} />
            {item.user?.email && <MailLink email={item.user?.email} />}
          </Fragment>
        ),
      },
      {
        header: t('fields.compensations'),
        valueGetter: (item) => (
          <Stack direction="row" spacing={1}>
            <StylizedNumber value={item.compensation} unit="%" />
            <span>{'/'}</span>
            <StylizedNumber value={item.payoutCompensation} unit="%" />
          </Stack>
        ),
      },
      {
        header: t('fields.work_status'),
        valueGetter: (item) => (
          <StatusLabel
            label={traderUtils.getWorkStatusLabel(item.workStatus)}
            status={item.workStatus}
          />
        ),
      },
      {
        header: t('fields.payout_max_actve_orders'),
        valueGetter: (item) =>
          formatUtils.formatNumber(
            item.payoutMaxActiveOrders || payoutMaxActiveOrders,
          ),
        valueClassName: 'tw-w-[240px]',
      },
      ...(canManage
        ? [
            {
              header: t('fields.groups'),
              multiValueRenderer: {
                itemsGetter: (item: Trader) => item.groups,
                valueGetter: (group: Group) => (
                  <div key={group.id}>{group?.name}</div>
                ),
              },
            },
            dataGridColumns.getActionsColumn({
              handleEdit: (item: Trader) => handleEditClick(item.id),
            }),
          ]
        : []),
    ],
    [canManage, handleEditClick, payoutMaxActiveOrders, t],
  );

  const filtersDefinitions: FilterDefinition<TraderFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.Trader,
        traders: queryResultTraders.data,
        getDisplayName: (traderId: string) =>
          find(queryResultTraders.data, { id: traderId })?.user?.name,
      },
      {
        label: t('filters.work_status'),
        name: 'workStatus',
        type: FilterDefinitionType.Enum,
        getDisplayName: traderUtils.getWorkStatusLabel,
        enum: TraderWorkStatus,
      },
      {
        label: t('filters.group'),
        name: 'groupId',
        hidden: !canManage,
        type: FilterDefinitionType.Select,
        getDisplayName: (groupId: string) =>
          find(queryResultGroups.data, { id: groupId })?.name,
        options: formUtils.getOptions(queryResultGroups.data || []),
      } as FilterDefinition<TraderFilters>,
    ],
    [canManage, queryResultGroups.data, queryResultTraders.data, t, tCommon],
  );

  return (
    <CrudPage
      header={{ title: t('title') }}
      filters={{ filtersDefinitions }}
      table={{ queryResult, columns }}
    />
  );
};
