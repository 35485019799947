import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { Shop } from 'types';

import { createCrudApi } from './crud.api';

const path = '/shop';
const url = `${env.apiUrl}${path}`;

const getReferrals = async (): Promise<Shop[]> =>
  (await axios.get(`${url}/referrals`)).data;

const crudApi = createCrudApi<Shop>(path);

export const shopsApi = {
  ...crudApi,
  getAllAsMerchant: crudApi.getAllAsRole(UserRole.Merchant), // just a shortcut as it's often in use
  getReferrals,
};
