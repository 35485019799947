import axios from 'axios';

import { env } from 'config';
import { OrdersTab, UserRole } from 'enums';
import {
  PayinOrder,
  PayinOrderDispute,
  ResolvePayinOrderDispute,
  PaginatedData,
  QueryParams,
  CreateOrderDto,
  ExportOrdersPayload,
  OrderReceipt,
  OrderStatusHistory,
} from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/orders`;
const exportUrl = `${env.dataApiUrl}/orders`;

const getAllPaginatedAsRole =
  (role: UserRole, tab: OrdersTab) =>
  async (params: QueryParams): Promise<PaginatedData<PayinOrder>> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}/${tab}`, params)).data;

const getOrderExistsByRequisitesAsRole =
  (role: UserRole) =>
  async (requisitesId: string): Promise<{ exists: boolean }> =>
    (await axios.get(`${url}/${role}/exists/requisites/${requisitesId}`)).data;

const confirm = async (order: Partial<PayinOrder>): Promise<PayinOrder> =>
  (await axios.post(`${url}/trader/${order.id}/confirm`, order)).data;

const disputeAsRole =
  (role: UserRole) =>
  async ({
    id,
    data,
  }: {
    id: string;
    data: PayinOrderDispute;
  }): Promise<PayinOrder> =>
    (await axios.post(`${url}/${role}/${id}/dispute`, data)).data;

const resolveDisputeAsRole =
  (role: UserRole) =>
  async ({
    id,
    data,
  }: {
    id: string;
    data: ResolvePayinOrderDispute;
  }): Promise<PayinOrder> =>
    (await axios.post(`${url}/${role}/${id}/resolve-dispute`, data)).data;

const cancelCompletedAsRole =
  (role: UserRole) =>
  async (id: string): Promise<PayinOrder> =>
    (await axios.post(`${url}/${role}/${id}/cancel-completed`)).data;

const cancelledToDisputeAsRole =
  (role: UserRole) =>
  async (id: string): Promise<PayinOrder> =>
    (await axios.post(`${url}/${role}/${id}/cancelled-to-dispute`)).data;

const createAsRole =
  (role: UserRole) =>
  async (order: CreateOrderDto): Promise<PayinOrder> =>
    (await axios.post(`${url}/${role}`, order)).data;

const getOrderStatusHistoryAsRole =
  (role: UserRole) =>
  async (orderId: string): Promise<OrderStatusHistory[]> =>
    (await axios.get(`${url}/${role}/${orderId}/status-history`)).data;

const getOrderReceiptsAsRole =
  (role: UserRole) =>
  async (orderId: string): Promise<OrderReceipt[]> =>
    (await axios.get(`${url}/${role}/${orderId}/receipts`)).data;

const generateReceiptUrlAsRole =
  (role: UserRole) =>
  async ({ orderId, body }: { orderId: string; body: OrderReceipt }) =>
    (await axios.post(`${url}/${role}/${orderId}/receipts/url`, body)).data;

const exportAsRole =
  (role: UserRole) =>
  ({ data, filters }: { data: ExportOrdersPayload; filters: string }) =>
    apiUtils.requestDownload(
      {
        url: `${exportUrl}/${role}/export`,
        method: 'post',
        data,
        params: { filters },
      },
      `${data.state}-orders-${Date.now()}.xlsx`,
    );

const getExportColumnsAsRole =
  (role: UserRole) => async (): Promise<{ columns: string[] }> =>
    (await axios.get(`${exportUrl}/${role}/export/columns`)).data;

export const ordersApi = {
  getAllPaginatedAsRole,
  getOrderExistsByRequisitesAsRole,
  generateReceiptUrlAsRole,
  getOrderReceiptsAsRole,
  confirm,
  disputeAsRole,
  resolveDisputeAsRole,
  cancelledToDisputeAsRole,
  createAsRole,
  cancelCompletedAsRole,
  exportAsRole,
  getExportColumnsAsRole,
  getOrderStatusHistoryAsRole,
};
