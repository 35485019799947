import { CircularProgress } from '@mui/material';
import { filter, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { internalWalletsApi } from 'api';
import {
  CopyText,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
} from 'components';
import { QueryKey } from 'enums';
import { WalletInformationIcon, WalletResources } from 'features/wallets';
import { useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalWallet } from 'types';
import { formatUtils } from 'utils';

export const WalletsTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets',
  });

  const [resources, setResources] = useState<{ [id: string]: any }>({});
  const [resourcesLoading, setResourcesLoading] = useState<string[]>([]);

  const queryResult = usePartialQuery(
    QueryKey.InternalWalletsArchive,
    internalWalletsApi.getAllArchivePaginated,
  );

  const { mutate: getResources } = useMutation(
    internalWalletsApi.getResources,
    {
      onMutate: (itemId: string) => {
        setResourcesLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setResources((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setResourcesLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'none',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalWallet>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.p2p_provider'),
        valueGetter: (item) => item.p2pProvider?.name,
      },
      {
        header: t('fields.user'),
        valueGetter: (item) => (
          <div>
            <div>{item.shop?.user?.name || item.user?.name}</div>
            {item.shop && <div>{formatUtils.formatName(item.shop)}</div>}
          </div>
        ),
      },
      {
        header: t('fields.address'),
        valueGetter: (item) => <CopyText text={item.address} />,
      },
      {
        header: t('fields.archive'),
        valueGetter: (item) => formatUtils.formatDate(item.archivedAt),
      },
      {
        header: t('fields.resources'),
        valueGetter: (item) => {
          if (resourcesLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <WalletResources
              resources={resources[item.id]}
              disabled={!isEmpty(resourcesLoading)}
              getResources={() => getResources(item.id)}
            />
          );
        },
      },
    ],
    [t, getResources, resources, resourcesLoading],
  );

  return (
    <CrudTable
      columns={columns}
      queryResult={queryResult}
      paginated
      actions={[
        {
          type: CrudTableActionType.Custom,
          render: (item) => <WalletInformationIcon item={item} />,
        },
      ]}
    />
  );
};
