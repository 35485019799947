import i18next from 'i18next';
import { Fragment } from 'react';

import {
  CopyText,
  CopyTextId,
  DataGridColumnDefinition,
  dataGridColumns,
} from 'components';
import { WebhookMethod, WebhookStatus } from 'enums';
import { Webhook, WebhookHistory } from 'types';

import { formatUtils } from './format.utils';

const getStatusLabel = (status: WebhookStatus) =>
  i18next.t(`features.webhooks.status.${status}`, { defaultValue: status });

const getCommonColumns = (): DataGridColumnDefinition<
  Webhook | WebhookHistory
>[] => {
  const { t } = i18next;
  const prefix = 'features.webhooks';

  return [
    dataGridColumns.getIdColumn(),
    {
      header: t(`${prefix}.columns.request`),
      valueGetter: (item) => (
        <Fragment>
          <CopyText
            label={`[${item.method || WebhookMethod.Post}] ${item.url}`}
            text={item.url}
          />
        </Fragment>
      ),
      valueClassName: 'tw-max-w-[200px] tw-break-all',
    },
    {
      header: t(`${prefix}.columns.reason`),
      valueGetter: (item) => {
        if (item.orderId) {
          return (
            <div>
              <div>{t(`${prefix}.fields.reason_payin`)}</div>
              <div>
                <CopyTextId id={item.orderId} disableIconColor />
              </div>
            </div>
          );
        }
        if (item.payoutOrderId) {
          return (
            <div>
              <div>{t(`${prefix}.fields.reason_payout`)}</div>
              <div>
                <CopyTextId id={item.payoutOrderId} disableIconColor />
              </div>
            </div>
          );
        }
      },
    },
    {
      header: t(`${prefix}.columns.shop`),
      valueGetter: (item) =>
        formatUtils.formatName(item.order?.shop || item.payoutOrder?.shop),
    },
  ];
};

export const webhooksUtils = {
  getStatusLabel,
  getCommonColumns,
};
