import { find } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { internalTransferApi } from 'api';
import { DataGridColumnDefinition } from 'components';
import { QueryKey, InternalTransferTab } from 'enums';
import { InternalTransfers } from 'features/internal-transfers';
import { usePartialQuery, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalTransfer, Shop } from 'types';

type Props = {
  queryResultShops?: UseQueryResult<Shop[]>;
};

export const ArchiveInternalTransfers: React.FC<Props> = ({
  queryResultShops,
}) => {
  const { role } = useUser();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { p2pProviders } = useUserContext();

  const queryResult = usePartialQuery(
    QueryKey.InternalTransfers,
    internalTransferApi.getAllPaginatedByRole(
      role,
      InternalTransferTab.Archived,
    ),
  );

  const additionalColumns: DataGridColumnDefinition<InternalTransfer>[] =
    useMemo(
      () => [
        {
          header: t('fields.recipient'),
          valueGetter: (item) => {
            const shopName = find(queryResultShops?.data, {
              id: item.recipientAsset?.shopId,
            })?.name;
            const p2pProviderName = item.recipientAsset?.p2pProviderId
              ? find(p2pProviders, { id: item.recipientAsset?.p2pProviderId })
                  ?.name
              : null;

            return (
              <Fragment>
                <div>{item.recipientUser?.name}</div>
                {shopName && <div>{shopName}</div>}
                {p2pProviderName && <div>{p2pProviderName}</div>}
              </Fragment>
            );
          },
        },
      ],
      [queryResultShops, p2pProviders, t],
    );

  return (
    <InternalTransfers
      queryResult={queryResult}
      queryResultShops={queryResultShops}
      additionalColumns={additionalColumns}
      hideTitle
    />
  );
};
