import { CircularProgress } from '@mui/material';
import { filter, isEmpty } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { internalWalletsApi } from 'api';
import {
  CopyText,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
} from 'components';
import { QueryKey } from 'enums';
import { WalletInformationIcon, WalletResources } from 'features/wallets';
import { useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalWallet, WalletResourcesData } from 'types';
import { formatUtils } from 'utils';

export const InternalUserWallets: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets',
  });

  const [resources, setResources] = useState<{
    [id: string]: WalletResourcesData;
  }>({});
  const [resourcesLoading, setResourcesLoading] = useState<string[]>([]);

  const queryResult = usePartialQuery(
    QueryKey.InternalUsersWallets,
    internalWalletsApi.getAllOfUsersPaginated,
  );

  const { mutate: getResources } = useMutation(
    internalWalletsApi.getResources,
    {
      onMutate: (itemId: string) => {
        setResourcesLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setResources((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setResourcesLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'none',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalWallet>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.user'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.user?.name}</div>
            {item.shop?.name && (
              <div>{`(${formatUtils.formatName(item.shop)})`}</div>
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.address'),
        valueGetter: (item) => (
          <Fragment>
            <div className="tw-uppercase">{item.network}</div>
            <CopyText text={item.address} />
          </Fragment>
        ),
      },
      {
        header: t('fields.resources'),
        valueGetter: (item) => {
          if (resourcesLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <WalletResources
              resources={resources[item.id]}
              disabled={!isEmpty(resourcesLoading)}
              getResources={() => getResources(item.id)}
            />
          );
        },
      },
    ],
    [t, getResources, resources, resourcesLoading],
  );

  return (
    <CrudTable
      columns={columns}
      queryResult={queryResult}
      paginated
      actions={[
        {
          type: CrudTableActionType.Custom,
          render: (item) => <WalletInformationIcon item={item} />,
        },
      ]}
    />
  );
};
