import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import { includes } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ordersApi } from 'api';
import { CloseFormikDialogResult } from 'components';
import { OrderStatusDetails, QueryKey } from 'enums';
import { ResolveDisputeDialog } from 'features';
import { useMutation, useUser } from 'hooks';
import { PayinOrder, ResolvePayinOrderDispute } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayinOrder;
};

export const OrderResolveDisputeColumn: React.FC<Props> = ({ order }) => {
  const queryClient = useQueryClient();
  const { role, isTrader, isManager } = useUser();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onStatusChange = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.DisputeOrders);
    queryClient.invalidateQueries(QueryKey.TraderDisputeOrders);
    queryClient.invalidateQueries(QueryKey.ProviderDisputeOrders);
    setDialogOpen(false);
  }, [queryClient]);

  const {
    mutate: requestResolveOrderDispute,
    error: resolveOrderDisputeAsAdminError,
  } = useMutation<
    PayinOrder,
    AxiosError,
    {
      id: string;
      data: ResolvePayinOrderDispute;
    }
  >(ordersApi.resolveDisputeAsRole(role), {
    onSuccess: onStatusChange,
  });

  const canTraderResolve = useMemo(() => {
    const statuses = [
      OrderStatusDetails.DisputeNoPayment,
      OrderStatusDetails.TraderConfirmTimeout,
      OrderStatusDetails.DisputeRevertCancelled,
    ];
    return isTrader && includes(statuses, order?.statusDetails);
  }, [order, isTrader]);

  const canResolve = useMemo(
    () => isManager || canTraderResolve,
    [isManager, canTraderResolve],
  );

  const resolveOrderDispute = useCallback(
    (resolveOrderDispute: ResolvePayinOrderDispute) => {
      requestResolveOrderDispute({
        id: order.id,
        data: resolveOrderDispute,
      });
    },
    [requestResolveOrderDispute, order.id],
  );

  const handleDialogClose = useCallback(
    ({ ok, data }: CloseFormikDialogResult<ResolvePayinOrderDispute>) => {
      if (ok && data) {
        resolveOrderDispute(data.values);
      } else {
        setDialogOpen(false);
      }
    },
    [resolveOrderDispute],
  );

  const handleClick = useCallback((event: any) => {
    (event as MouseEvent)?.stopPropagation();
    (event as MouseEvent)?.preventDefault();
    setDialogOpen(true);
  }, []);

  return (
    <div>
      <div className="tw-flex tw-items-center">
        {order.statusDetails && (
          <div className="tw-mr-1">
            {orderUtils.getStatusDetailsLabel(order.statusDetails)}
          </div>
        )}
        {canResolve && (
          <IconButton color="inherit" onClick={handleClick}>
            <EditIcon color="primary" />
          </IconButton>
        )}
      </div>
      {/* TODO: re-implement order components with utils to not render modal for each row */}
      <ResolveDisputeDialog
        open={dialogOpen}
        order={order}
        error={resolveOrderDisputeAsAdminError}
        amountDisabled={isTrader}
        onClose={handleDialogClose}
      ></ResolveDisputeDialog>
    </div>
  );
};
