import React, { useMemo, useContext } from 'react';
import { UseQueryResult } from 'react-query';

import { automationSourcesApi } from 'api';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import { AutomationSource, PaginatedData, TradeMethod } from 'types';

const AutomationSourcesContext = React.createContext<{
  tradeMethod?: TradeMethod;
  automationSourcesQuery: UseQueryResult<PaginatedData<AutomationSource>>;
  isExtended?: boolean;
} | null>(null);

export const AutomationSourcesProvider = ({
  children,
  tradeMethod,
  isExtended,
}: {
  children: React.ReactNode;
  tradeMethod?: TradeMethod;
  isExtended?: boolean;
}) => {
  const fetchAutomationSources = useMemo(() => {
    if (isExtended) {
      return automationSourcesApi.getAllPaginated;
    }

    if (!tradeMethod) {
      return () =>
        Promise.resolve({
          items: [],
          page: 0,
          pages: 0,
          count: 0,
        } as PaginatedData<AutomationSource>);
    }

    return automationSourcesApi.getAllPaginatedByTradeMethodId(tradeMethod.id);
  }, [tradeMethod, isExtended]);

  const automationSourcesQuery = usePartialQuery(
    [QueryKey.AutomationSources, tradeMethod?.id],
    fetchAutomationSources,
    { enabled: tradeMethod !== null || isExtended },
  );

  return (
    <AutomationSourcesContext.Provider
      value={{
        tradeMethod,
        automationSourcesQuery,
        isExtended,
      }}
    >
      {children}
    </AutomationSourcesContext.Provider>
  );
};

export const useAutomationSources = () => {
  const context = useContext(AutomationSourcesContext);
  if (context === null) {
    throw new Error(
      'useAutomationSources must be used within a AutomationSourcesProvider',
    );
  }

  return context;
};
