import moment from 'moment';
import { useQuery } from 'react-query';

import { assetBalanceHistoryApi, shopsApi, usersApi } from 'api';
import { BalanceHistory } from 'components';
import { QueryKey } from 'enums';
import {
  usePartialQuery,
  useQueryFilters,
  useShopsQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { AssetBalanceHistoryFilters } from 'types';
import { assetBalanceHistoryUtils } from 'utils';

export const AdminAssetBalanceHistoryPage: React.FC = () => {
  const { p2pProviders } = useUserContext();
  const { data: users = [] } = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );

  const { role } = useUser();

  const { data: shops = [] } = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  useQueryFilters<AssetBalanceHistoryFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const queryResult = usePartialQuery(
    QueryKey.AssetBalanceHistory,
    assetBalanceHistoryApi.getAllPaginated,
    { useCachedCount: true },
  );

  return (
    <BalanceHistory
      queryResult={queryResult}
      additionalColumns={assetBalanceHistoryUtils.getAdminColumns()}
      additionalFilters={[
        ...assetBalanceHistoryUtils.getAdminFilters(users, p2pProviders),
        ...assetBalanceHistoryUtils.getShopFilters(shops),
      ]}
    />
  );
};
