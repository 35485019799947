import { find } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { shopsApi, usersApi, webhooksApi } from 'api';
import { CrudTable, DataGridColumnDefinition, QueryFilters } from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import {
  usePartialQuery,
  useQueryFilters,
  useShopsQuery,
  useUser,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, WebhookFilters, WebhookHistory } from 'types';
import { filtersUtils, formatUtils, webhooksUtils } from 'utils';

export const ActiveTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.webhooks',
  });
  const { t: tCommon } = useTranslation();
  const { role, isAdmin } = useUser();
  const { filters } = useQueryFilters<WebhookFilters>();

  const queryResult = usePartialQuery(
    QueryKey.Webhooks,
    webhooksApi.getAllPaginatedAsRole(role),
    { useCachedCount: true },
  );
  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultMerchants = useQuery(
    QueryKey.UsersMerchants,
    usersApi.getAllMerchants,
    { enabled: isAdmin },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<WebhookHistory>[] => [
      ...webhooksUtils.getCommonColumns(),
      {
        header: t('columns.attempts'),
        valueGetter: (item) => (
          <Fragment>
            {!!item.attempts && (
              <div className="tw-mb-1">{`${t('fields.attempts')}: ${
                item.attempts
              }`}</div>
            )}
            {item.latestAttemptAt && (
              <div className="tw-text-xs">
                <div>{t('fields.latest_attempt_at')}</div>
                <div>{formatUtils.formatDate(item.latestAttemptAt)}</div>
              </div>
            )}
            {item.nextAttemptAt && (
              <div className="tw-text-xs">
                <div>{t('fields.next_attempt_at')}</div>
                <div>{formatUtils.formatDate(item.nextAttemptAt)}</div>
              </div>
            )}
          </Fragment>
        ),
      },
    ],
    [t],
  );

  const filtersDefinitions: FilterDefinition<WebhookFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.id'),
        name: 'id',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.url'),
        name: 'url',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.merchant'),
        name: 'merchantId',
        type: FilterDefinitionType.User,
        users: queryResultMerchants.data,
        getDisplayName: (merchantId: string) =>
          find(queryResultMerchants.data, { id: merchantId })?.name,
        hidden: !isAdmin,
      },
      {
        label: tCommon('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops: queryResultShops.data,
        getDisplayName: (shopId: string) =>
          find(queryResultShops.data, { id: shopId })?.name,
      },
      {
        label: tCommon('filters.payin_order_id'),
        name: 'orderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.payout_order_id'),
        name: 'payoutOrderId',
        type: FilterDefinitionType.Text,
      },
    ],
    [isAdmin, queryResultMerchants.data, queryResultShops.data, tCommon],
  );

  return (
    <Fragment>
      <QueryFilters
        filtersDefinitions={[
          ...filtersUtils.getCommonFilters(filters),
          ...filtersDefinitions,
        ]}
      />
      <CrudTable queryResult={queryResult} columns={columns} paginated />
    </Fragment>
  );
};
