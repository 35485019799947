import { CircularProgress } from '@mui/material';
import { filter, find, isEmpty } from 'lodash';
import { useState, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { internalWalletsApi } from 'api';
import {
  DataGridColumnDefinition,
  dataGridColumns,
  CopyText,
  CrudTableActionType,
  CrudPage,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { WalletResources, WalletInformationIcon } from 'features/wallets';
import { useMutation, usePartialQuery, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { WalletResourcesData, InternalWallet, FilterDefinition } from 'types';
import { formUtils } from 'utils';

type P2PProviderWalletsFilters = {
  p2pProviderId: string;
  shopId: string;
};

export const P2PProviderWallets: React.FC = () => {
  const queryClient = useQueryClient();
  const { p2pProviders } = useUserContext();
  const activeP2PProviders = filter(p2pProviders, ({ archived }) => !archived);
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets',
  });

  const { t: tCommon } = useTranslation();

  const [resources, setResources] = useState<{
    [id: string]: WalletResourcesData;
  }>({});
  const [resourcesLoading, setResourcesLoading] = useState<string[]>([]);

  const queryResult = usePartialQuery(
    QueryKey.InternalP2PProviderWallets,
    internalWalletsApi.getAllOfP2PProvidersPaginated,
  );

  const p2pProvidersOptions = useMemo(
    () => formUtils.getOptions(activeP2PProviders || []),
    [activeP2PProviders],
  );

  const archiveMutation = useMutation(internalWalletsApi.archive, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.InternalP2PProviderWallets);
    },
    notifierType: 'remove',
  });

  const { mutate: getResources } = useMutation(
    internalWalletsApi.getResources,
    {
      onMutate: (itemId: string) => {
        setResourcesLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setResources((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setResourcesLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'execute',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalWallet>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.p2p_provider'),
        valueGetter: (item) => item.p2pProvider?.name,
      },
      {
        header: t('fields.address'),
        valueGetter: (item) => (
          <Fragment>
            <div className="tw-uppercase">{item.network}</div>
            <CopyText text={item.address} />
          </Fragment>
        ),
      },
      {
        header: t('fields.resources'),
        valueGetter: (item) => {
          if (resourcesLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <WalletResources
              resources={resources[item.id]}
              disabled={!isEmpty(resourcesLoading)}
              getResources={() => getResources(item.id)}
            />
          );
        },
      },
    ],
    [t, resourcesLoading, resources, getResources],
  );

  const filtersDefinitions: FilterDefinition<P2PProviderWalletsFilters>[] =
    useMemo(
      () => [
        {
          label: tCommon('filters.p2p_provider'),
          name: 'p2pProviderId',
          type: FilterDefinitionType.Select,
          options: p2pProvidersOptions,
          getDisplayName: (id: string) =>
            find(activeP2PProviders, { id })?.name,
        },
      ],
      [p2pProvidersOptions, activeP2PProviders, tCommon],
    );

  return (
    <CrudPage
      filters={{ filtersDefinitions }}
      table={{
        columns,
        queryResult,
        paginated: true,
        actions: [
          {
            type: CrudTableActionType.Custom,
            render: (item) => <WalletInformationIcon item={item} />,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item: InternalWallet, { close }) =>
              archiveMutation.mutate(item.id, { onSuccess: close }),
          },
        ],
      }}
    />
  );
};
