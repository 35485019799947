import React from 'react';

import { assetBalanceHistoryApi } from 'api';
import { BalanceHistory, DataGridColumnDefinition } from 'components';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import {
  AssetBalanceHistory,
  AssetBalanceHistoryFilters,
  FilterDefinition,
} from 'types';

type Props = {
  additionalFilters?: FilterDefinition<AssetBalanceHistoryFilters>[];
  additionalColumns?: DataGridColumnDefinition<AssetBalanceHistory>[];
};
export const MyAssetBalanceHistory: React.FC<Props> = ({
  additionalFilters = [],
  additionalColumns = [],
}) => {
  const queryResult = usePartialQuery(
    QueryKey.AssetBalanceHistory,
    assetBalanceHistoryApi.getMyPaginated,
  );

  return (
    <BalanceHistory
      queryResult={queryResult}
      additionalColumns={additionalColumns}
      additionalFilters={additionalFilters}
    />
  );
};
