import axios from 'axios';

import { env } from 'config';
import {
  InternalPlatformWalletConfigDto,
  InternalWallet,
  QueryParams,
  WalletResourcesData,
} from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/internal-wallets`;

const getMy = async (): Promise<InternalWallet[]> =>
  (await axios.get(`${url}/my`)).data;

const create = async (shopId?: string): Promise<InternalWallet> =>
  (await axios.post(shopId ? `${url}/shop/${shopId}` : url)).data;

const createPlatform = async (
  data: InternalPlatformWalletConfigDto,
): Promise<InternalWallet> => (await axios.post(`${url}/platform`, data)).data;

const createP2PProvider = async (
  p2pProviderId: string,
): Promise<InternalWallet> =>
  (await axios.post(`${url}/p2p-provider/${p2pProviderId}`)).data;

const archive = async (id: string): Promise<InternalWallet> =>
  (await axios.post(`${url}/${id}/archive`)).data;

const updatePlatform = async ({
  id,
  data,
}: {
  id: string;
  data: InternalPlatformWalletConfigDto;
}): Promise<InternalWallet> =>
  (await axios.patch(`${url}/platform/${id}/config`, data)).data;

const getAllOfUsersPaginated = async (
  params?: QueryParams,
): Promise<InternalWallet[]> =>
  (await apiUtils.getWithQueryParams(`${url}/users`, params)).data;

const getAllOfPlatformPaginated = async (
  params?: QueryParams,
): Promise<InternalWallet[]> =>
  (await apiUtils.getWithQueryParams(`${url}/platform`, params)).data;

const getAllOfP2PProvidersPaginated = async (
  params?: QueryParams,
): Promise<InternalWallet[]> =>
  (await apiUtils.getWithQueryParams(`${url}/p2p-providers`, params)).data;

const getAllArchivePaginated = async (
  params?: QueryParams,
): Promise<InternalWallet[]> =>
  (await apiUtils.getWithQueryParams(`${url}/archive`, params)).data;

const getResources = async (id: string): Promise<WalletResourcesData> =>
  (await axios.get(`${url}/${id}/resources`)).data;

const getOnePlatform = async (id: string): Promise<InternalWallet> =>
  (await axios.get(`${url}/platform/${id}`)).data;

const getPrivateKey = async ({
  id,
  data,
}: {
  id: string;
  data: { password: string };
}): Promise<{ privateKey: string }> =>
  (await axios.post(`${url}/${id}/private-key`, data)).data;

export const internalWalletsApi = {
  create,
  createP2PProvider,
  createPlatform,
  updatePlatform,
  getMy,
  getAllOfUsersPaginated,
  getAllOfPlatformPaginated,
  getAllOfP2PProvidersPaginated,
  getAllArchivePaginated,
  getResources,
  getOnePlatform,
  getPrivateKey,
  archive,
};
