import { PaginationOptions } from 'types';

import { useQueryData } from './use-query-data.hook';

export function useQueryPagination(initialData?: Partial<PaginationOptions>) {
  const { data, initialized, setData, reset } = useQueryData<PaginationOptions>(
    'pagination',
    initialData,
  );

  return {
    pagination: data,
    initialized,
    setPagination: setData,
    resetPagination: reset,
  };
}
