import { AxiosError } from 'axios';
import { findIndex, split } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { payoutOrdersApi } from 'api';
import {
  DataGrid,
  DataGridColumnDefinition,
  SidebarLayout,
  SidebarSize,
} from 'components';
import { QueryKey, StatusCode } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  PayoutOrder,
  PayoutOrderDispute,
  PayoutOrderReject,
  ResolvePayoutOrderDispute,
} from 'types';

import { ConfirmPaymentDialog } from '../ConfirmPaymentDialog';
import { PayoutOrderDetails } from '../PayoutOrderDetails';

type Props = {
  items: PayoutOrder[];
  columns: DataGridColumnDefinition<PayoutOrder>[];
  selectedOrder: PayoutOrder | null;
  queryKey: string;
  handleOrderSelection: (orderId: string | null) => void;
};

export const OrderListWithAction: React.FC<Props> = ({
  items,
  columns,
  selectedOrder,
  queryKey,
  handleOrderSelection,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  const { role } = useUser();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries(QueryKey.Notifications);
  }, [queryClient, queryKey]);

  const handleListUpdated = useCallback(
    (orderId: string | null) => {
      handleOrderSelection(orderId);
      invalidateQueries();
    },
    [handleOrderSelection, invalidateQueries],
  );

  const { mutate: acceptOrder } = useMutation(payoutOrdersApi.acceptAsTrader, {
    onSuccess: (data) => {
      handleListUpdated(data?.id);
    },
    notifierMessages: {
      error: (error: AxiosError<any>) => {
        const status = error?.response?.status;
        if (status === StatusCode.BadRequest) {
          const message = error.response?.data.message;
          const reason = split(
            message,
            'Unable to accept by the reason: ',
          )?.[1];
          if (reason) {
            invalidateQueries();

            return t(`accept_error.${reason}`, {
              defaultValue: reason,
            });
          }
        }
      },
    },
    notifierType: 'execute',
  });

  const { mutate: rejectOrder } = useMutation(payoutOrdersApi.rejectAsTrader, {
    onSuccess: () => {
      handleListUpdated(null);
    },
  });

  const { mutate: disputeOrder } = useMutation(
    payoutOrdersApi.disputeAsTrader,
    {
      onSuccess: (data) => {
        handleListUpdated(data?.id);
      },
    },
  );

  const { mutate: cancelledToDispute } = useMutation(
    payoutOrdersApi.disputeCancelledAsRole(role),
    {
      onSuccess: (data) => {
        handleListUpdated(data?.id);
      },
    },
  );

  const { mutate: cancelCompleted } = useMutation(
    payoutOrdersApi.cancelCompletedAsRole(role),
    {
      onSuccess: (data) => {
        handleListUpdated(data?.id);
      },
    },
  );

  const { mutate: resolveDispute } = useMutation(
    payoutOrdersApi.resolveDisputeAsRole(role),
    {
      onSuccess: (data) => {
        handleListUpdated(data?.id);
      },
    },
  );

  const handleAccept = useCallback(() => {
    if (selectedOrder) {
      acceptOrder(selectedOrder);
    }
  }, [acceptOrder, selectedOrder]);

  const handleDispute = useCallback(
    (data: PayoutOrderDispute) => {
      if (selectedOrder) {
        disputeOrder({ id: selectedOrder.id, data });
      }
    },
    [disputeOrder, selectedOrder],
  );

  const handleConfirm = useCallback(() => {
    setConfirmDialogOpen(true);
  }, []);

  const handleReject = useCallback(
    (data: PayoutOrderReject) => {
      if (selectedOrder) {
        rejectOrder({ id: selectedOrder.id, data });
      }
    },
    [rejectOrder, selectedOrder],
  );

  const handleClose = useCallback(() => {
    handleOrderSelection(null);
  }, [handleOrderSelection]);

  const handleCloseConfirmPaymentDialog = useCallback(() => {
    setConfirmDialogOpen(false);
  }, []);

  const handleResolveDispute = useCallback(
    (data: ResolvePayoutOrderDispute) => {
      if (selectedOrder) {
        resolveDispute({ id: selectedOrder.id, data });
      }
    },
    [resolveDispute, selectedOrder],
  );

  const handleCancelledToDispute = useCallback(() => {
    if (selectedOrder) {
      cancelledToDispute(selectedOrder.id);
    }
  }, [cancelledToDispute, selectedOrder]);

  const handleCancelCompleted = useCallback(() => {
    if (selectedOrder) {
      cancelCompleted(selectedOrder.id);
    }
  }, [cancelCompleted, selectedOrder]);

  return (
    <SidebarLayout
      sidebarProps={{
        open: !!selectedOrder,
        title: t('details.title'),
        size: SidebarSize.md,
        onClose: handleClose,
        content: selectedOrder && (
          <PayoutOrderDetails
            order={selectedOrder}
            onReject={handleReject}
            onDispute={handleDispute}
            onCancelledToDispute={handleCancelledToDispute}
            onCancelCompleted={handleCancelCompleted}
            onResolveDispute={handleResolveDispute}
            onConfirm={handleConfirm}
            onAccept={handleAccept}
          />
        ),
      }}
    >
      <DataGrid
        columns={columns}
        data={items}
        onRowClick={(order) => handleOrderSelection(order?.id)}
        selectedRowIndex={findIndex(items, {
          id: selectedOrder?.id,
        })}
      />
      <ConfirmPaymentDialog
        open={confirmDialogOpen}
        order={selectedOrder}
        onClose={handleCloseConfirmPaymentDialog}
      />
    </SidebarLayout>
  );
};
