import { xor } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { assetCurrencyExchangeRequestsApi } from 'api';
import {
  ConfirmButton,
  CrudPage,
  DataGridColumnDefinition,
  dataGridColumns,
  DateLabel,
  Money,
} from 'components';
import { AssetCurrencyExchangeRequestStatus, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AssetCurrencyExchangeRequest } from 'types';
import { formatUtils } from 'utils';

import { CurrencyExchangeStatusLabel } from './CurrencyExchangeStatusLabel';

type Props = {
  archive?: boolean;
};

export const CurrencyExchangeRequests: React.FC<Props> = ({ archive }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.currency_exchange',
  });

  const [processInProgress, setProcessInProgress] = useState<string[]>([]);

  const { isManager, isMerchant } = useUser();

  const toggleProcessInProgress = useCallback(
    (item: AssetCurrencyExchangeRequest) => {
      setProcessInProgress((prev) => xor(prev, [item.id]));
    },
    [],
  );

  const queryResult = usePartialQuery(
    QueryKey.AssetCurrencyExchangeRequests,
    archive
      ? assetCurrencyExchangeRequestsApi.getAllArchivePaginated
      : assetCurrencyExchangeRequestsApi.getAllPaginated,
  );

  const { mutate: update } = useMutation(
    assetCurrencyExchangeRequestsApi.update,
    {
      notifierType: 'execute',
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.AssetCurrencyExchangeRequests);
      },
    },
  );

  const updateStatus = useCallback(
    (
      item: AssetCurrencyExchangeRequest,
      status: AssetCurrencyExchangeRequestStatus,
    ) => {
      toggleProcessInProgress(item);
      update(
        { id: item.id, data: { status } },
        {
          onSettled: () => {
            toggleProcessInProgress(item);
          },
        },
      );
    },
    [toggleProcessInProgress, update],
  );

  const renderActions = useCallback(
    (item: AssetCurrencyExchangeRequest) => {
      if (isManager) {
        return (
          <div className="tw-grid tw-grid-cols-2 tw-gap-2">
            <ConfirmButton
              variant="outlined"
              size="small"
              disabled={processInProgress.includes(item.id)}
              onConfirm={() =>
                updateStatus(item, AssetCurrencyExchangeRequestStatus.Completed)
              }
            >
              {t('buttons.approve')}
            </ConfirmButton>
            <ConfirmButton
              variant="outlined"
              color="error"
              size="small"
              disabled={processInProgress.includes(item.id)}
              onConfirm={() =>
                updateStatus(item, AssetCurrencyExchangeRequestStatus.Cancelled)
              }
            >
              {t('buttons.reject')}
            </ConfirmButton>
          </div>
        );
      }
      return (
        <ConfirmButton
          variant="outlined"
          color="error"
          size="small"
          disabled={processInProgress.includes(item.id)}
          onConfirm={() =>
            updateStatus(item, AssetCurrencyExchangeRequestStatus.Revoked)
          }
        >
          {t('buttons.revoke')}
        </ConfirmButton>
      );
    },
    [isManager, processInProgress, t, updateStatus],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<AssetCurrencyExchangeRequest>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('columns.user'),
        valueGetter: (item) => (
          <Fragment>
            {isManager && <div>{item.fromAsset.user?.name}</div>}
            {item.fromAsset.shop?.name && (
              <div>{formatUtils.formatName(item.fromAsset.shop)}</div>
            )}
            {item.fromAsset.p2pProvider?.name && (
              <div>{item.fromAsset.p2pProvider?.name}</div>
            )}
          </Fragment>
        ),
        hidden: !isManager,
      },
      {
        header: t('columns.shop'),
        valueGetter: (item) => formatUtils.formatName(item.fromAsset.shop),
        hidden: !isMerchant,
      },
      {
        header: t('columns.exchange'),
        valueGetter: (item) => (
          <Fragment>
            <Money
              value={item.fromAssetAmount}
              assetCurrencyId={item.fromAsset.assetCurrencyId}
              symbol
            />
            {' → '}
            <Money
              value={item.toAssetAmount}
              assetCurrencyId={item.toAsset.assetCurrencyId}
              symbol
            />
          </Fragment>
        ),
      },
      {
        header: t('columns.price'),
        valueKey: 'price',
        valueFormatter: formatUtils.formatNumber,
      },
      {
        header: t('columns.processed'),
        valueGetter: (item) => (
          <div>
            {item.processor?.name && <div>{item.processor?.name}</div>}
            <DateLabel>{formatUtils.formatDate(item.processedAt)}</DateLabel>
          </div>
        ),
        hidden: !archive,
      },
      {
        header: t('columns.status'),
        valueGetter: (item) => (
          <CurrencyExchangeStatusLabel status={item.status} />
        ),
      },
      {
        header: t('columns.actions'),
        valueGetter: renderActions,
        hidden: archive,
      },
    ],
    [t, isManager, isMerchant, archive, renderActions],
  );

  return (
    <CrudPage
      filters={{
        filtersDefinitions: [],
      }}
      table={{
        queryResult,
        paginated: true,
        columns,
      }}
    />
  );
};
