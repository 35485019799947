import { createContext } from 'react';

import { Shop, User } from 'types';

export type PayoutOrdersContextProps = {
  shops?: Shop[];
  traders?: User[];
  merchants?: User[];
};

export const PayoutOrdersContext = createContext<PayoutOrdersContextProps>({
  shops: [],
  traders: [],
  merchants: [],
});
