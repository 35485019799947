import React from 'react';
import { useQuery } from 'react-query';

import { fundsRequestsApi, shopsApi, usersApi } from 'api';
import { QueryKey } from 'enums';
import { FundsRequests } from 'features';
import { usePartialQuery, useShopsQuery, useUser } from 'hooks';

export const ArchiveFundsRequests: React.FC = () => {
  const { data: users = [] } = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );

  const { role } = useUser();

  const { data: shops = [] } = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  const queryResult = usePartialQuery(
    QueryKey.FundsRequests,
    fundsRequestsApi.getAllArchivePaginated,
  );

  return (
    <FundsRequests
      queryResult={queryResult}
      hideTitle
      users={users}
      shops={shops}
    />
  );
};
