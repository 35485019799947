import moment from 'moment';
import React from 'react';

import { assetBalanceHistoryApi } from 'api';
import { BalanceHistory } from 'components';
import { QueryKey } from 'enums';
import { usePartialQuery, useQueryFilters } from 'hooks';
import { AssetBalanceHistoryFilters } from 'types';

export const TraderBalanceHistoryPage: React.FC = () => {
  useQueryFilters<AssetBalanceHistoryFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const queryResult = usePartialQuery(
    QueryKey.AssetBalanceHistory,
    assetBalanceHistoryApi.getMyPaginated,
  );

  return <BalanceHistory queryResult={queryResult} />;
};
