import { AxiosError } from 'axios';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { filter, find, isEmpty, some } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { assetsApi, fundsRequestsApi, shopsApi, usersApi } from 'api';
import {
  CloseDialogHandler,
  CloseDialogResult,
  DataWrapper,
  Dialog,
  FormControls,
  FormikNumericField,
  FormikSelect,
  UserSelect,
} from 'components';
import { Network, OperationType, QueryKey, UserRole } from 'enums';
import { useCurrencies, useMutation, useShopsQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CreateFundsRequest } from 'types';
import { assetUtils, fundsRequestUtils, validationUtils } from 'utils';

type Props = {
  open: boolean;
  onClose: CloseDialogHandler;
};

type Values = {
  operationType: OperationType;
  userId: string;
  assetId: string;
  assetCurrencyId: string;
  amount: number;
};

// Admin creates funds requests with no fees. Use this const to match API
const DEFAULT_FEE = 2;

export const CreateFundRequestDialog: React.FC<Props> = ({ open, onClose }) => {
  const { role } = useUser();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request.create_funds_requests_dialog',
  });
  const queryClient = useQueryClient();
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const queryResultAssets = useQuery(QueryKey.Assets, assetsApi.getAll);
  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultUsers = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );

  const { getAssetCurrencySymbol, assetCurrenciesOptions } = useCurrencies();

  const [assetIsRequired, setAssetIsRequired] = useState(true);
  const initialValues: Values = useMemo(
    () => ({
      operationType: OperationType.Deposit,
      userId: '',
      assetId: '',
      assetCurrencyId: '',
      amount: 0,
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        operationType: Yup.string().required(tCommon('errors.required')),
        userId: Yup.string().required(tCommon('errors.required')),
        ...(assetIsRequired && {
          assetId: Yup.string()
            .required(tCommon('errors.required'))
            .uuid(tCommon('errors.invalid')),
        }),
        assetCurrencyId: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
        amount: Yup.number()
          .required(tCommon('errors.required'))
          .positive(tCommon('errors.natural_number')),
      }),
    [assetIsRequired, tCommon],
  );

  const { mutate: createDeposit, isLoading: isCreateDepositLoading } =
    useMutation(fundsRequestsApi.createDepositForUser);

  const { mutate: createWithDrawal, isLoading: isCreateWithdrawalLoading } =
    useMutation(fundsRequestsApi.createWithdrawalForUser);

  const formatFundsRequest = useCallback(
    (values: Values): CreateFundsRequest => ({
      assetId: values.assetId,
      amount: values.amount,
      paymentAmount: values.amount,
      assetCurrencyId: values.assetCurrencyId,
      fee: DEFAULT_FEE,
      network: Network.TRC20,
      address: '',
      ...(values.operationType === OperationType.Deposit && { hash: '' }),
    }),
    [],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const userId = values.userId;
      const fundsRequest = formatFundsRequest(values);

      let apiFn =
        values.operationType === OperationType.Deposit
          ? createDeposit
          : createWithDrawal;

      return apiFn(
        { userId, fundsRequest },
        {
          onSuccess: (data) => {
            formikHelpers.resetForm();
            queryClient.invalidateQueries(QueryKey.Assets);
            onClose({ ok: true, data });
          },
          onSettled: () => formikHelpers.setSubmitting(false),
          onError: (error) => {
            formikHelpers.setErrors(
              validationUtils.getFormErrors(error as AxiosError),
            );
          },
        },
      );
    },
    [formatFundsRequest, queryClient, createDeposit, createWithDrawal, onClose],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const { setFieldValue, setFieldTouched, values, isSubmitting } = formik;

  const isLoading = useMemo(
    () =>
      isSubmitting ||
      queryResultUsers?.isLoading ||
      queryResultShops?.isLoading ||
      isCreateDepositLoading ||
      isCreateWithdrawalLoading,
    [
      isSubmitting,
      queryResultUsers,
      queryResultShops,
      isCreateDepositLoading,
      isCreateWithdrawalLoading,
    ],
  );

  const availableAssets = useMemo(
    () =>
      assetUtils.filterAssets(queryResultAssets.data, {
        userId: formik.values.userId,
        assetCurrencyId: formik.values.assetCurrencyId,
      }),
    [queryResultAssets, formik.values],
  );

  const assetOptions = useMemo(
    () => assetUtils.getAssetOptions(availableAssets),
    [availableAssets],
  );

  const availableUsers = useMemo(
    () =>
      filter(
        queryResultUsers.data,
        (user) =>
          ![UserRole.Admin, UserRole.Operator, UserRole.TechOperator].includes(
            user.role,
          ),
      ),
    [queryResultUsers?.data],
  );

  const handleClose = useCallback(
    (result: CloseDialogResult, formikHelpers: FormikHelpers<Values>) => {
      if (result.ok) {
        formikHelpers.submitForm();
      } else {
        formikHelpers.resetForm();
        onClose(result);
      }
    },
    [onClose],
  );

  const operationTypesOptions = useMemo(
    () =>
      Object.values(OperationType).map((value) => ({
        value,
        label: fundsRequestUtils.getOperationLabel(value),
      })),
    [],
  );

  useEffect(() => {
    if (values.userId && values.assetCurrencyId) {
      const userAssetsExist = some(
        queryResultAssets.data,
        (asset) =>
          asset.userId === values.userId &&
          asset.assetCurrencyId === values.assetCurrencyId,
      );
      setAssetIsRequired(
        userAssetsExist ||
          values.operationType === OperationType.Withdrawal ||
          find(queryResultUsers.data, { id: values.userId })?.role ===
            UserRole.Merchant,
      );
      setFieldValue('assetId', '');
      setFieldTouched('assetId', false);
    }
  }, [
    queryResultAssets.data,
    values.userId,
    values.assetCurrencyId,
    values.operationType,
    setFieldValue,
    setFieldTouched,
    queryResultUsers.data,
  ]);

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={(data) => handleClose(data, formik)}
        okDisabled={isLoading || !formik.isValid}
        title={t('title')}
      >
        <DataWrapper isLoading={isLoading}>
          <Form>
            <FormControls>
              <FormikSelect
                options={operationTypesOptions}
                label={t('operation_type')}
                className="tw-w-full"
                name="operationType"
              />
              <UserSelect name="userId" fullWidth users={availableUsers} />
              <FormikSelect
                label={t('asset_currency')}
                name="assetCurrencyId"
                options={assetCurrenciesOptions}
              />
              <FormikSelect
                className="tw-w-full"
                label={t('asset')}
                name="assetId"
                options={assetOptions}
                disabled={
                  isEmpty(assetOptions) ||
                  !assetIsRequired ||
                  !formik.values.userId ||
                  !formik.values.assetCurrencyId
                }
                {...(!assetIsRequired &&
                  formik.values.operationType === OperationType.Deposit && {
                    helperText: t('no_asset_label'),
                  })}
              />
              <FormikNumericField
                label={t('amount')}
                name="amount"
                className="tw-w-full"
                allowNegative={false}
                disabled={assetIsRequired && !formik.values.assetId}
                suffix={getAssetCurrencySymbol(formik.values.assetCurrencyId)}
              />
            </FormControls>
          </Form>
        </DataWrapper>
      </Dialog>
    </FormikProvider>
  );
};
