import styled from '@emotion/styled';
import { AccountBalance as AccountBalanceIcon } from '@mui/icons-material';
import { Chip, Drawer as MuiDrawer, ListItemButton } from '@mui/material';
import { green } from '@mui/material/colors';
import { lighten } from 'polished';

import {
  DRAWER_COLLAPSED_WIDTH,
  DRAWER_TRANSITION,
  DRAWER_WIDTH,
} from '../styles';

export const DRAWER_TOGGLE_SIZE = 24;
export const DRAWER_TOGGLE_SPACING = 5;
export const MUI_TOOLBAR_HEIGHT = 64;

export const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: ${(props) => props.theme.mixins.toolbar.minHeight}px;
  padding-left: 0;
  padding-right: 0;
  justify-content: start;
  cursor: pointer;
  flex-grow: 0;
  margin-right: ${(props) => props.theme.spacing(4)};
  margin-left: ${(props) => props.theme.spacing(4)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: ${(props) =>
      props.theme.mixins.toolbar[props.theme.breakpoints.up('sm')].minHeight}px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

export const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: 5px;
  padding: 3px 0;
`;

export const BrandIcon = styled(AccountBalanceIcon)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
`;

export const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

export const DrawerContent = styled.div`
  width: ${DRAWER_WIDTH}px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${(props) => props.theme.sidebar.header.background};
  height: 100%;
`;

export const DrawerTogglePlaceholder = styled.div`
  min-height: ${DRAWER_TOGGLE_SIZE + DRAWER_TOGGLE_SPACING * 2}px;
  background-color: ${(props) => props.theme.sidebar.background};
`;

export const DrawerToggle = styled.div<{ expanded: boolean; visible: boolean }>`
  left: ${(props) =>
    (props.expanded ? DRAWER_WIDTH : DRAWER_COLLAPSED_WIDTH) - 10}px;
  position: fixed;
  top: ${MUI_TOOLBAR_HEIGHT + DRAWER_TOGGLE_SPACING}px;
  padding: 0;
  min-width: 0;
  border-radius: 100%;
  color: #fff;
  width: ${DRAWER_TOGGLE_SIZE}px;
  height: ${DRAWER_TOGGLE_SIZE}px;
  border: 2px solid #fff;
  background-color: ${(props) => props.theme.sidebar.background};
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: ${(props) => props.theme.zIndex.drawer};
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0ms,
    left ${DRAWER_TRANSITION};
  will-change: left;

  &:hover {
    background-color: ${(props) =>
      lighten(0.15, props.theme.sidebar.background)}};
  }
`;
