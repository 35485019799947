import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { PaginatedData, QueryParams, Requisites } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/requisites`;

const getAllForTraderAsRole =
  (role: UserRole) =>
  async (traderId: string): Promise<Requisites[]> =>
    (await axios.get(`${url}/${role}/trader/${traderId}`)).data;

const getAllPaginated = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await apiUtils.getWithQueryParams(url, params)).data;

const getForGroupAsRole =
  (role: UserRole) =>
  async (groupId: string): Promise<Requisites[]> =>
    (await axios.get(`${url}/${role}/group/${groupId}`)).data;

const getAllPaginatedAsOperator = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await apiUtils.getWithQueryParams(`${url}/operator`, params)).data;

const getAllPaginatedAsOperatorArchived = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await apiUtils.getWithQueryParams(`${url}/operator/archived`, params)).data;

const getAllMyPaginated = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await apiUtils.getWithQueryParams(`${url}/my/paginated`, params)).data;

const getAllMy = async (): Promise<Requisites[]> =>
  (await axios.get(`${url}/my`)).data;

const create = async (requisites: Partial<Requisites>): Promise<Requisites> =>
  (await axios.post(url, requisites)).data;

const update = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<Requisites>;
}): Promise<Requisites> => (await axios.patch(`${url}/${id}`, requisites)).data;

const updateOrderAutomation = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<Requisites>;
}): Promise<Requisites> =>
  (await axios.patch(`${url}/${id}/order-automation`, requisites)).data;

const updateStatus = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<Requisites>;
}): Promise<Requisites> =>
  (await axios.patch(`${url}/${id}/status`, requisites)).data;

const unblock = async (id: string): Promise<Requisites> =>
  (await axios.patch(`${url}/${id}/unblock`)).data;

const block = async (params: {
  id: string;
  blockedMessage: string;
}): Promise<Requisites> =>
  (
    await axios.patch(`${url}/${params.id}/block`, {
      blockedMessage: params.blockedMessage,
    })
  ).data;

const findOne = async (id: string): Promise<Requisites> =>
  (await axios.get(`${url}/${id}`)).data;

const remove = async (id: string): Promise<Requisites> =>
  (await axios.delete(`${url}/${id}`)).data;

const archive = async (id: string): Promise<Requisites> =>
  (await axios.post(`${url}/${id}/archive`)).data;

const restore = async (id: string): Promise<Requisites> =>
  (await axios.post(`${url}/${id}/restore`)).data;

const getAllPaginatedArchived = async (
  params: QueryParams,
): Promise<PaginatedData<Requisites>> =>
  (await apiUtils.getWithQueryParams(`${url}/archived`, params)).data;

export const requisitesApi = {
  getAllForTraderAsRole,
  getAllPaginatedArchived,
  getAllPaginated,
  getForGroupAsRole,
  getAllPaginatedAsOperator,
  getAllPaginatedAsOperatorArchived,
  getAllMyPaginated,
  getAllMy,
  create,
  update,
  updateOrderAutomation,
  findOne,
  remove,
  updateStatus,
  block,
  unblock,
  archive,
  restore,
};
