import { find } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { shopsApi } from 'api';
import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  FilterDefinitionType,
  OrdersTab,
  QueryKey,
} from 'enums';
import { Orders } from 'features/orders';
import {
  useCurrencies,
  useQueryFilters,
  useShopsQuery,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { formUtils, orderUtils, requisitesUtils } from 'utils';

export const MerchantCompletedOrders: React.FC = () => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  const { paymentTypes, banks } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsMerchant,
  );

  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.customer'),
        name: 'customerId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops: queryResultShops.data,
        getDisplayName: (shopId: string) =>
          find(queryResultShops.data, { id: shopId })?.name,
      },
      {
        label: tCommon('filters.external_order_id'),
        name: 'externalOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
      {
        label: tCommon('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tCommon('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tCommon('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tCommon('filters.amount'),
        name: 'amount',
        type: FilterDefinitionType.Numeric,
      },
      ...requisitesUtils.getRequisitesFieldsFilters<OrderFilters>(),
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      paymentTypes,
      queryResultShops.data,
      tCommon,
    ],
  );

  return (
    <Orders
      tab={OrdersTab.Completed}
      additionalFilters={additionalFilters}
      additionalColumns={[
        AdditionalOrderColumn.CustomerContacts,
        AdditionalOrderColumn.Shop,
        AdditionalOrderColumn.CustomerPayment,
      ]}
    />
  );
};
